import React, { useEffect, useState } from "react";
import StandardContainer from "../styled/generic/StandardContainer";
import Button from "../styled/generic/Button";
import StandardAppContainerRounded from "../styled/generic/StandardAppContainerRounded";
import FormBox from "../styled/generic/FormBox";
import TextField from "../styled/generic/TextField";
import SpaceBetween from "../styled/generic/SpaceBetween";
import Select from "../styled/generic/Select";
import {
  Avatar,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import OrgPickerDropdown from "../styled/generic/OrgPickerDropdown";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { Delete, DeleteOutline } from "@mui/icons-material";
import HorizBox from "../styled/generic/HorizBox";
import BoxSpaceBetween from "../styled/generic/BoxSpaceBetween";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import Api from "../../helpers/Api";
import PaginatedEntityDropdown from "../styled/CommonComponents/PaginatedEntityDropdown";

const EditApproval = () => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [title, setTitle] = useState("");
  const TYPE_OPTIONS = ["HRM", "Procurement"];
  const [type, setType] = useState(TYPE_OPTIONS[0]);
  const [description, setDescription] = useState("");
  const [belongsToOrg, setBelongsToOrg] = useState(false);
  const [org, setOrg] = useState("");
  const [specifyApprovers, setSpecifyApprovers] = useState(false);
  const [directManagerAsApprover, setDirectManagerAsApprover] = useState(false);
  const [letRequesterEnter, setLetRequesterEnter] = useState(false);
  const [steps, setSteps] = useState([]);
  const [profileId, setProfileId] = useState();
  const [templateId, setTemplateId] = useState();
  const [template, setTemplate] = useState();
  const [defaultOrgId, setDefaultOrgId] = useState();

  const addStep = () => {
    setSteps((prev) => [
      ...prev,
      {
        _id: dayjs().valueOf(),
        approvers: [],
        approversNeeded: "all",
        approvalsNeededCustom: 1,
      },
    ]);
  };

  const addApproverToStep = (stepId, approverProfile) => {
    let newSteps = steps.map((step) => {
      if (step._id === stepId) {
        return {
          ...step,
          approvers: [
            ...step.approvers,
            {
              profile: approverProfile,
              mandatory: false,
            },
          ],
        };
      }
      return step;
    });
    setSteps(newSteps);
  };

  const removeApproverFromStep = (stepId, approverProfile) => {
    let newSteps = steps.map((step) => {
      if (step._id === stepId) {
        return {
          ...step,
          approvers: step.approvers.filter(
            (approver) => approver.profile !== approverProfile
          ),
        };
      }
      return step;
    });
    setSteps(newSteps);
  };

  const updateApproverFromStep = (stepId, approverProfile, mandatory) => {
    let newSteps = steps.map((step) => {
      if (step._id === stepId) {
        return {
          ...step,
          approvers: step.approvers.map((approver) => {
            if (approver.profile === approverProfile) {
              return {
                ...approver,
                mandatory,
              };
            }
            return approver;
          }),
        };
      }
      return step;
    });
    setSteps(newSteps);
  };

  const updateStep = (stepId, step) => {
    let newSteps = steps.map((s) => {
      if (s._id === stepId) {
        return step;
      }
      return s;
    });
    setSteps(newSteps);
  };

  const removeStep = (stepId) => {
    let newSteps = steps.filter((step) => step._id !== stepId);
    setSteps(newSteps);
  };

  const createApprovalTemplate = async () => {
    try {
      // Check if we have atleast one step with atleast one approver
      let hasAtleastOneStepWithAtleastOneApprover = steps.some(
        (step) => step.approvers.length > 0
      );
      if (!hasAtleastOneStepWithAtleastOneApprover) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "Please add atleast one step with atleast one approver",
          },
        });
        return;
      }

      const { data } = await Api.post("/approval/template/create", {
        title,
        type,
        description,
        belongsToOrg,
        org,
        specifyApprovers,
        directManagerAsApprover,
        letRequesterEnter,
        steps,
        currentProfileId: profileId,
      });
      if (data?._id) {
        //history.goBack();
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Approval template created",
          },
        });
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "Unable to create approval template",
          },
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to create approval template",
        },
      });
    }
  };

  const updateApprovalTemplate = async () => {
    try {
      // Check if we have atleast one step with atleast one approver
      let hasAtleastOneStepWithAtleastOneApprover = steps.some(
        (step) => step.approvers.length > 0
      );
      if (!hasAtleastOneStepWithAtleastOneApprover) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "Please add atleast one step with atleast one approver",
          },
        });
        return;
      }

      const { data } = await Api.post("/approval/template/update", {
        _id: templateId,
        updateBody: {
          title,
          type,
          description,
          belongsToOrg,
          org,
          specifyApprovers,
          directManagerAsApprover,
          letRequesterEnter,
        },
        newStages: steps,
      });
      if (data?._id) {
        //history.goBack();
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Approval template updated",
          },
        });
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "Unable to update approval template",
          },
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to update approval template",
        },
      });
    }
  };

  const getApprovalTemplate = async () => {
    try {
      const { data } = await Api.post("/approval/template/get-by-id", {
        _id: templateId,
      });
      if (data) {
        setTemplate(data);
        setTitle(data?.title);
        setType(data?.type);
        setDescription(data?.description);
        setBelongsToOrg(data?.belongsToOrg);
        setOrg(data?.organization);
        setDefaultOrgId(data?.organization);
        setSpecifyApprovers(data?.specifyApprovers);
        setDirectManagerAsApprover(data?.allowManagerApproval);
        setLetRequesterEnter(data?.allowRequesterEnter);
        setSteps(data?.stages);
        if (data?.stages?.length === 0) {
          addStep();
        }
      }
    } catch (error) {
      console.log("Error: ", error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to fetch approval template",
        },
      });
    }
  };

  // Set profile id from query params
  useEffect(() => {
    let _profileId = new URLSearchParams(location.search).get("profileId");
    setProfileId(_profileId);
    let _templateId = new URLSearchParams(location.search).get("templateId");
    setTemplateId(_templateId);
  }, []);

  useEffect(() => {
    if (templateId) {
      getApprovalTemplate();
    } else {
      addStep();
    }
  }, [templateId]);

  return (
    <StandardContainer
      showAppBar={true}
      appBarTitle={template?._id ? "Edit Approval" : "Create Approval"}
      appBarActions={
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (template?._id) {
              updateApprovalTemplate();
            } else {
              createApprovalTemplate();
            }
          }}
        >
          {template?._id ? "Update" : "Create"}
        </Button>
      }
    >
      <StandardAppContainerRounded>
        <SpaceBetween
          left={
            <FormBox label="Approval Title">
              <TextField
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Enter"
                fullWidth
              />
            </FormBox>
          }
        />
        <SpaceBetween
          left={
            <FormBox label="Type">
              <Select
                value={type}
                onChange={(e) => setType(e.target.value)}
                fullWidth
              >
                {TYPE_OPTIONS.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormBox>
          }
        />
        <SpaceBetween
          left={
            <FormBox label="Description">
              <TextField
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Enter"
                fullWidth
                multiline
                rows={4}
              />
            </FormBox>
          }
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={belongsToOrg}
              onChange={(e, checked) => setBelongsToOrg(checked)}
            />
          }
          label="Does this belong to the organization?"
        />
        <SpaceBetween
          left={
            <FormBox label="Organization">
              <OrgPickerDropdown
                selectedOrg={org}
                setSelectedOrg={setOrg}
                hideLabel={true}
                fullWidth={true}
                disabled={!belongsToOrg || defaultOrgId}
                defaultOrganizationId={defaultOrgId}
              />
            </FormBox>
          }
        />
      </StandardAppContainerRounded>
      <StandardAppContainerRounded>
        <SpaceBetween
          left={
            <FormBox label="Approvers">
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <FormControlLabel
                    label="Specify Approvers"
                    control={
                      <Checkbox
                        checked={specifyApprovers}
                        onChange={(e, checked) => setSpecifyApprovers(checked)}
                      />
                    }
                    sx={{ mt: 2 }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    label="Direct Manager as Approver"
                    control={
                      <Checkbox
                        checked={directManagerAsApprover}
                        onChange={(e, checked) =>
                          setDirectManagerAsApprover(checked)
                        }
                      />
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    label="Let Requester Enter"
                    control={
                      <Checkbox
                        checked={letRequesterEnter}
                        onChange={(e, checked) => setLetRequesterEnter(checked)}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </FormBox>
          }
        />
        <SpaceBetween
          left={
            <Box>
              {steps?.map((step, index) => (
                <Box
                  sx={{
                    p: 2,
                    border: "1px solid rgba(0,0,0,0.10)",
                    borderRadius: "10px",
                    mb: 3,
                  }}
                  key={step?._id}
                >
                  <Typography variant="h6" gutterBottom>
                    Stage {index + 1}
                  </Typography>
                  <FormBox label="Add member">
                    <PaginatedEntityDropdown
                      onChange={(value) => {
                        addApproverToStep(step?._id, value?.data);
                      }}
                      isMulti={false}
                      entity="User"
                      curEntityId={user?.profile}
                    />
                  </FormBox>
                  <Box>
                    {step?.approvers?.map((approver) => (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            border: "1px solid rgba(0,0,0,0.10)",
                            borderRadius: "10px",
                            p: 1,
                            mt: 1,
                            mr: 4,
                            flex: 1,
                          }}
                        >
                          <HorizBox>
                            <Avatar
                              src={
                                approver?.profile?.parent?.displayPicture?.url
                              }
                            />
                            <Typography variant="body1" gutterBottom>
                              {approver?.profile?.parent?.displayName}
                            </Typography>
                          </HorizBox>
                          <IconButton
                            onClick={() =>
                              removeApproverFromStep(
                                step?._id,
                                approver?.profile
                              )
                            }
                          >
                            <DeleteOutline />
                          </IconButton>
                        </Box>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={approver?.mandatory}
                              onChange={(e, checked) =>
                                updateApproverFromStep(
                                  step?._id,
                                  approver?.profile,
                                  checked
                                )
                              }
                            />
                          }
                          label="Mandatory"
                        />
                      </Box>
                    ))}
                  </Box>
                  <FormBox label="Specify how many approvals are required to move to the next step">
                    <RadioGroup
                      onChange={(e, val) => {
                        updateStep(step?._id, {
                          ...step,
                          approvalsNeeded: val,
                        });
                      }}
                    >
                      <FormControlLabel
                        value="custom"
                        control={<Radio />}
                        label={
                          <TextField
                            size="small"
                            value={step?.approvalsNeededCustom}
                            onChange={(e) =>
                              updateStep(step?._id, {
                                ...step,
                                approvalsNeededCustom: e.target.value,
                              })
                            }
                            type="number"
                            inputProps={{
                              min: 1,
                              max: step?.approvers?.length,
                            }}
                            disabled={
                              step?.approvers?.length === 0 ||
                              step?.approvalsNeeded !== "custom"
                            }
                          />
                        }
                      />
                      <FormControlLabel
                        value="all"
                        control={<Radio />}
                        label="All signature required"
                      />
                    </RadioGroup>
                  </FormBox>
                  <BoxSpaceBetween>
                    {index === steps?.length - 1 && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={addStep}
                      >
                        Add Step
                      </Button>
                    )}
                    {steps?.length > 1 && (
                      <Box>
                        <Button
                          variant="contained"
                          color="error"
                          startIcon={<Delete />}
                          onClick={() => removeStep(step?._id)}
                        >
                          Remove Step
                        </Button>
                      </Box>
                    )}
                  </BoxSpaceBetween>
                </Box>
              ))}
            </Box>
          }
        />
      </StandardAppContainerRounded>
    </StandardContainer>
  );
};

export default EditApproval;
