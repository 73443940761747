import { useState, useEffect } from "react";
import Api from "../../helpers/Api";
import arrayToReducer from "../../helpers/arrayToReducer";

function useProfileIssueData(profileId) {
  const [issueIds, setIssueIds] = useState([]);

  const [issueDictionary, setIssueDictionary] = useState({});
  const [totalIssues, setTotalIssues] = useState(0);

  const [loading, setLoading] = useState(false);

  const [shouldRefetch, setShouldRefetch] = useState(true);
  const [totalPages, setTotalPages] = useState("1");
  const [currentPage, setCurrentPage] = useState("1");

  /*
  {
    // Filter condition usage:
    templates: [],
    projects: [],
    organizations: []
  }

  */
  const [filterCondition, setFilterCondition] = useState(null);

  const getData = async () => {
    setLoading(true);

    const res = await Api.post("issue/getProfileIssues", {
      profile: profileId,
      pageNumber: currentPage,
      filterCondition: filterCondition,
    });

    const data = res.data;
    console.log('issue/getProfileIssues ', data)

    setLoading(false);

    if (data) {
      const issues = data.issues;
      const length = data.issueLength;
      const tPages = data.totalPages;
      const cPage = data.currentPage;

      const { newDict, idArr } = arrayToReducer(issues);

      setIssueIds(idArr);
      setIssueDictionary(newDict);
      setTotalIssues(length);
      setTotalPages(tPages);
      setCurrentPage(cPage);
    }
  };

  useEffect(() => {
    if (profileId && shouldRefetch) {
      getData();
      setShouldRefetch(false);
    }
  }, [profileId, shouldRefetch]);

  const refetchIssues = (pageNum, filterData) => {
    if (filterData) {
      setFilterCondition(filterData);
    }

    setCurrentPage(pageNum);
    setShouldRefetch(true);
  };

  return {
    issueIds,
    setIssueIds,
    issueDictionary,
    setIssueDictionary,
    totalIssues,
    loading,
    refetchIssues,
    currentPage,
    totalPages,
  };
}

export default useProfileIssueData;
