import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import Api from "../../helpers/Api";
import Response from "../responses/response";
import AnswerApproval from "./answer.approval";
import { ButtonGroup } from "@material-ui/core";
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '1%',
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        display: 'flex',
        alignItems: 'center'
    },
    resetContainer: {
        padding: theme.spacing(3),
    },

    text: {
        fontSize: 20,
        fontWeight: "bold",
        margin: "0rem 1rem",
        fontColor: "#111111",
        marginLeft: '2%'
    },
}));

export default function AnswerApprovalStepper(props) {
    const classes = useStyles();
    const history = useHistory();
    const { approvals, loader, setLoader } = props;
    const { user } = useSelector((state) => state.auth);

    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    return (
        <div className={classes.root}>
            <Stepper activeStep={activeStep} orientation="vertical">
                {approvals && approvals.map((approval, index) => (
                    <Step key={index}>
                        <StepLabel>
                            <div style={{ display: 'flex', justifyContent: "flex-start" }}>
                                <Typography variant="h5" className={classes.text}>
                                    Title
                                    <Typography style={{ width: '10rem' }}>{approval?.displayName}</Typography>
                                </Typography>
                                <Typography variant="h5" className={classes.text}>
                                    Status
                                    <Typography style={{ width: '5rem' }}>{approval?.status}</Typography>
                                </Typography>
                                <Typography variant="h5" className={classes.text}>
                                    Priority
                                    <Typography style={{
                                        width: '30%',
                                        height: '40%',
                                        borderRadius: '60%',
                                        backgroundColor: approval?.priority >= 0 && approval?.priority <= 10
                                            ? approval?.priority > 5 ? 'red' : 'green'
                                            : 'blue',
                                        color: 'white',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        margin: '0px 20px 0px'
                                    }}>{approval?.priority}</Typography>
                                </Typography>
                                <Typography variant="h5" className={classes.text}>
                                    Due Date
                                    <Typography style={{ width: '100%' }}>
                                        {approval?.dueDate ? moment(approval?.dueDate).format('DD-MM-YYYY h:mm A') : 'None'}
                                    </Typography>
                                </Typography>
                            </div>
                        </StepLabel>
                        <StepContent>
                            <AnswerApproval approvalId={approval._id} loader={loader} setLoader={setLoader} />
                            <div className={classes.actionsContainer}>
                                <div>
                                    <Button
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        className={classes.button}
                                    >
                                        Back
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="inherit"
                                        onClick={handleNext}
                                        className={classes.button}
                                    >
                                        {activeStep === approvals.length - 1 ? 'Finish' : 'Next'}
                                    </Button>

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        // onClick={()=>setApprovalStatus("Approved")}
                                        className={classes.button}
                                    >
                                        {/* {activeStep === approvals.length - 1 ? 'Finish' : 'Next'} */}
                                        Approve

                                    </Button>

                                </div>
                            </div>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
            {activeStep === (approvals?.length || 0) && (
                <Paper square elevation={0} className={classes.resetContainer}>
                    <Typography>All steps completed - you&apos;re finished</Typography>
                    <Button onClick={handleReset} className={classes.button}>
                        Reset
                    </Button>
                </Paper>
            )}
        </div>
    );
}
