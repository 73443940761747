import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { Box, Button, CircularProgress, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, TextField, Typography, Slide, Switch, Grid, Drawer } from "@material-ui/core";
import useProfileIssueData from "../issue/useProfileIssueData";
import Api from "../../helpers/Api";
import arrayToReducer from "../../helpers/arrayToReducer";
import CustomFileUploadButton from "../file/Uploader/CustomFileUploadButton";
import ShareIconBtn from "../share/share.icon.btn";
import useShared from "../share/useShared";
import SharedList from "../share/sharedList";
import Divider from '@material-ui/core/Divider';
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import Response from "../responses/response";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { SlPencil } from "react-icons/sl";
import DescriptionInput from "../styled/description.input";
import AnswerApprovalStepper from "./answer.approval.stepper";
import ProfileAppbar from "../profile/profile.appbar.js";
import CreateBtn from "../styled/actionBtns/create.btn";
import Logs from "../logs";
import SmartCommentList from "../comment/smart.comment.list";

const useStyles = makeStyles((theme) => ({

    root: {
        display: "flex",
        flex: '70%',
        flexDirection: "column",
        marginTop: '4rem',
        // width: "70%",
        // height: "100vh",
        justifyContent: "center",
        alignItems: "flex-start",
    },
    commentRoot: {
        display: "flex",
        flex: '30%',
        flexDirection: "column",
        marginTop: '4rem',
        // width: "30%",
        // height: "100vh",
        justifyContent: "center",
        alignItems: "flex-start",
    },
    topBar: {
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid #ebeaea",
        backgroundColor: "white",
    },
    barLeft: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    backArrow: {
        fontSize: "30px",
        [theme.breakpoints.down("xs")]: {
            fontSize: "20px",
        },
    },
    headerTitle: {
        fontSize: "20px",
        fontWeight: "550",
        marginLeft: "10px",
        [theme.breakpoints.down("sm")]: {
            fontSize: "15px",
            fontWeight: "510",
            marginLeft: "0px",
        },
    },
    barRight: {
        display: "flex",
        marginRight: "1%",
        alignItems: "center",
        justifyContent: "space-between",
        "& .MuiButton-label": {
            textTransform: "capitalize",
        },
    },
    text: {
        fontSize: 20,
        fontWeight: "bold",
        margin: "0rem 1rem",
        fontColor: "#111111",
    },
    textAns: {
        fontSize: 15,
        margin: "0rem 1rem",
        fontColor: "#111111",
    },


    // categoryAndLocationTag: {
    //     display: "flex",
    //     justifyContent: "space-between",
    //     width: "100%",
    //     marginTop: "20px",
    //     padding: "0px 20px 10px",
    //     [theme.breakpoints.down("xs")]: {
    //         flexDirection: "column",
    //     },
    // },
    // categoryOrLocationCont: {
    //     width: "100%",
    //     minHeight: "300px",
    //     [theme.breakpoints.down("xs")]: {
    //         width: "100%",
    //         marginBottom: "15px",
    //     },
    // },
    formControl: {
        // marginTop: "2.5%",
        width: "15rem"
    },
    editBtn: {
        cursor: 'pointer',
        fontSize: "140%",
        top: 0,
        right: 0,
    },
}));

// const permitTypes = [
//     "Internal",
//     "Government",
//     "Client"
// ]
const permitStatuses = [
    "Open",
    "In Progress",
    "Closed"
]
const AnswerPermit = (props) => {
    const classes = useStyles();
    const history = useHistory();
    // const { permitId, setLoading } = props;
    const { permitId } = useParams();
    const { user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    const file = useSelector((state) => state.file);
    const { createdFileIds } = file;

    const [permit, setPermit] = useState(null);

    const [approvals, setApprovals] = useState(null);
    const [approvalId, setApprovalId] = useState([]);
    const [loader, setLoader] = useState(true);

    const [displayName, setDisplayName] = useState("");
    const [descriptions, setDescription] = useState("");
    const [dueDate, setDueDate] = useState(new Date());
    const [expiryDate, setExpiryDate] = useState(new Date());
    // const [permitType, setPermitType] = useState(permitTypes[0]);
    const [permitStatus, setPermitStatus] = useState(permitStatuses[0]);
    // const [templatePermit, setTemplatePermit] = useState(false);
    const [questions, setQuestions] = useState([]);

    const [showPermit, setShowPermit] = useState(false);

    const handleChangeShowPermit = () => {
        setShowPermit((prev) => !prev);
    }

    const getPermitById = async () => {
        try {
            const res = await Api.post("approvalPermit/getPermitById", {
                permitId: permitId,
            });
            const data = res?.data;
            if (data) {
                setPermit(data);
                setApprovals(data?.approvals);
                const { newDict, idArr } = arrayToReducer(data?.approvals);
                // setApprovals(newDict);

                setApprovalId(idArr);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (permitId) {
            getPermitById();
        }
    }, [])

    useEffect(() => {
        setDisplayName(permit?.displayName || "");
        setDescription(permit?.description || "");
        setDueDate(permit?.dueDate || new Date());
        setExpiryDate(permit?.expiryDate || new Date());
        // setPermitType(permit?.permitType || permitTypes[0]);
        setPermitStatus(permit?.permitStatus || permitStatuses[0]);
        // setTemplatePermit(permit?.isTemplate || false);
        setQuestions(permit?.questions || []);
    }, [permit])

    const [openEditView, setOpenEditView] = useState(false);

    //shared part
    const [open, setOpen] = useState(false);
    const oldShared = permit?.shared?.map((profile) => profile) || [];
    const [shared, setShared] = useState(oldShared);
    // const oldassigned = permit?.assigned?.map((profile) => profile) || [];
    // const [assigned, setAssigned] = useState(oldassigned);
    const [isPrivate, setPrivate] = useState(false);

    const sharedProps = useShared({
        initShared: shared,
        initAssigned: [],
    });

    var { privateButton, assignButton, assigness, sharedPeoples } = SharedList(
        sharedProps,
        isPrivate,
        setPrivate
    );
    //shared part

    //select menu part
    // const handleChangeSelectType = (val) => {
    //     setPermitType(val);
    // };
    const handleChangeSelectStatus = (val) => {
        setPermitStatus(val);
    };
    //select menu part

    // const [deletedImgIds, setDeletedImgIds] = useState([]);

    // const removeSingleImgFromReducerAndDelete = async (selectedId) => {
    //     const filteredFileIds = createdFileIds.filter((id) => id != selectedId);
    //     let deletedImgs = deletedImgIds;
    //     deletedImgs.push(selectedId);
    //     setDeletedImgIds(deletedImgs);

    //     dispatch({
    //         type: "AddFile",
    //         payload: {
    //             createdFileIds: [...filteredFileIds],
    //         },
    //     });
    // };

    const handleChangeSave = async () => {

        const Id = permit?._id
        const obj = {
            // isTemplate: templatePermit,
            displayName: displayName,
            description: descriptions,
            // permitType: permitType,
            dueDate: dueDate,
            expiryDate: expiryDate,
            permitStatus: permitStatus,

        }
        const res = await Api.post("approvalPermit/updatePermitById", { PermitId: Id, updatePermit: obj });

        const data = res?.data;
        if (data?._id) {
            dispatch({
                type: "AddApiAlert",
                payload: {
                    success: true,
                    message: "Permit Updated successfully",
                },
            });
            getPermitById();
            // history.goBack();
        } else {
            dispatch({
                type: "AddApiAlert",
                payload: {
                    success: false,
                    message: "Permit Updated failed",
                },
            });
        }
    };



    return (
        <div style={{ display: 'flex', width: '100%' }}>

            {/* <div className={classes.topBar}>
                <div className={classes.barLeft}>
                    <IconButton
                        onClick={() => {
                            history.goBack();
                        }}
                    >
                        <KeyboardBackspaceIcon className={classes.backArrow} />
                    </IconButton>

                    <Typography className={classes.headerTitle}>
                        Approval And Permit
                    </Typography>
                </div>
                <div className={classes.barRight}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleChangeSave}
                    >
                        Save
                    </Button>
                </div>
            </div> */}

            <ProfileAppbar
                name={`Edit Approval And Permit`}
                btns={
                    <>
                        {privateButton}
                        <ShareIconBtn
                            open={sharedProps?.open}
                            setOpen={sharedProps?.setOpen}
                        />
                        <div>

                            {/* {template?.pipeline?.length > 0 ? (
                                <StatusPicker
                                    pipeline={template?.pipeline}
                                    startState={template?.startState}
                                    status={status}
                                    setStatus={setStatus}
                                />
                            ) : null} */}
                        </div>
                        <CreateBtn
                            onClick={() => {
                                handleChangeSave();
                            }}
                        >
                            Update
                        </CreateBtn>
                    </>
                }
            />
            <div className={classes.root}>
                <Drawer anchor={"right"} open={open} onClose={() => setOpen(false)}>
                    <Logs
                        dataModelId={permit?._id}
                        dataModel="Permit"
                        setOpen={setOpen}
                    />

                </Drawer>
                {sharedProps?.shareDrawer}
                {sharedProps?.assignedDialog}

                {!showPermit && <div
                    style={{
                        flexGrow: "1",
                        padding: "1rem",
                        width: '100%',
                        // marginTop: "4%"
                    }}
                >
                    <Box width="100%" style={{ alignItems: 'center' }}>
                        <Typography variant="h5" className={classes.text}>
                            Permit
                        </Typography>
                    </Box>

                    <Box
                        component={Paper}
                        elevation={2}
                        style={{
                            backgroundColor: "#ffffff",
                            padding: "1rem",
                            // marginTop: "1rem",
                        }}
                    >
                        <Box style={{}}>
                            <div style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
                                <Typography variant="h5" className={classes.text}>
                                    Title
                                </Typography>
                                <IconButton
                                    className={classes.editBtn}
                                    onClick={() => {
                                        handleChangeShowPermit();
                                    }}
                                >
                                    <SlPencil />
                                </IconButton>
                            </div>

                            <Typography className={classes.textAns}>{displayName}</Typography>


                        </Box>

                        <Box style={{ marginTop: "1rem" }}>
                            <Typography variant="h5" className={classes.text}>
                                Description
                            </Typography>

                            <Typography className={classes.textAns} dangerouslySetInnerHTML={{ __html: descriptions }}></Typography>

                        </Box>

                    </Box>
                </div>}


                {showPermit && <div
                    style={{
                        flexGrow: "1",
                        padding: "1rem",
                        width: '100%',
                        // marginTop: "4%"
                    }}
                >
                    <Box width="100%" style={{ alignItems: 'center' }}>
                        <Typography variant="h5" className={classes.text}>
                            Permit
                        </Typography>
                    </Box>

                    <Box
                        component={Paper}
                        elevation={2}
                        style={{
                            backgroundColor: "#ffffff",
                            padding: "1rem",
                            // marginTop: "1rem",
                        }}
                    >
                        <Box>
                            <div style={{ display: 'flex', justifyContent: "space-between" }}>
                                <Typography variant="h5" className={classes.text}>
                                    Title
                                </Typography>

                                <IconButton
                                    className={classes.editBtn}
                                    onClick={() => {
                                        // setApprovalId(currentObj?._id);
                                        // setOpenEditView(true);
                                        handleChangeShowPermit();
                                    }}
                                >
                                    <ArrowBackIosIcon />
                                </IconButton>

                            </div>

                            <TextField
                                value={displayName}
                                placeholder={"Name"}
                                onChange={(e) => setDisplayName(e.target.value)}
                                variant="outlined"
                                style={{ padding: "1rem" }}
                                fullWidth
                                size="small"
                            />
                        </Box>

                        <Box style={{ marginTop: "1rem" }}>
                            <Typography variant="h5" className={classes.text}>
                                Description
                            </Typography>
                            <Box style={{ padding: "1rem" }}>
                                <DescriptionInput
                                    NoTitle
                                    description={descriptions}
                                    placeholder={"Permit Description"}
                                    setDescription={setDescription}
                                />
                            </Box>
                        </Box>

                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                        >

                            <Box>
                                <div style={{ padding: "1rem" }}>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-outlined-label">Permit Status</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={permitStatus}
                                            onChange={(e) => handleChangeSelectStatus(e.target.value)}
                                            label="Permit Status"
                                        >
                                            {permitStatuses.map((val, index) => (
                                                <MenuItem key={index} value={val}>{val}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </Box>


                            <Box>
                                <div style={{ padding: "1rem" }}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            variant="inline"
                                            margin="normal"
                                            label="Due Date"
                                            format="dd MMMM yyyy"
                                            value={dueDate}
                                            onChange={(date) => {
                                                setDueDate(date);
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: "bold",
                                                    fontSize: 24,
                                                    color: "#111111",
                                                },
                                            }}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            </Box>

                            <Box>
                                <div style={{ padding: "1rem" }}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            variant="inline"
                                            margin="normal"
                                            label="Expiry Date"
                                            format="dd MMMM yyyy"
                                            value={expiryDate}
                                            onChange={(date) => {
                                                setExpiryDate(date);
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: "bold",
                                                    fontSize: 24,
                                                    color: "#111111",
                                                },
                                            }}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            </Box>

                        </Box>
                    </Box>

                    <Box
                        component={Paper}
                        elevation={2}
                        style={{
                            backgroundColor: "#ffffff",
                            padding: "0.5rem",
                            // marginTop: "2rem",
                        }}
                    >
                        <Box width="100%" style={{ marginTop: "1rem" }}>
                            <Typography variant="h5" className={classes.text}>
                                Questions
                            </Typography>

                            <Box width="100%" style={{ padding: "1rem" }}>
                                <Response
                                    questions={questions}
                                // issue={issue}
                                />
                            </Box>
                        </Box>
                    </Box>


                    {/* <Paper className={classes.categoryAndLocationTag}>
                    <div className={classes.categoryOrLocationCont}>
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <h3>Shared</h3>
                            <ShareIconBtn
                                open={sharedProps?.open}
                                setOpen={sharedProps?.setOpen}
                            />
                        </div>
                        <div>{sharedPeoples}</div>
                    </div>
                </Paper> */}

                </div>}

                {!showPermit && <div
                    style={{
                        flexGrow: "1",
                        padding: "1rem",
                        width: '100%',
                        // marginTop: "4%"
                    }}
                >
                    <Box width="100%" style={{ alignItems: 'center' }}>
                        <Typography variant="h5" className={classes.text}>
                            Approval
                        </Typography>
                    </Box>

                    <AnswerApprovalStepper approvals={approvals} loader={loader} setLoader={setLoader} />

                </div>}

            </div>

            <div className={classes.commentRoot}>

                <div
                    style={{
                        flexGrow: "1",
                        padding: "2%",
                        width: '100%',
                        marginTop: "8%"
                    }}
                >

                    <Box
                        component={Paper}
                        elevation={2}
                        style={{
                            backgroundColor: "#ffffff",
                            // padding: "1rem",
                            width: '100%'
                        }}
                    >
                        <Typography variant="h5" className={classes.text}>
                            Comments
                        </Typography>

                        <SmartCommentList parent={permitId} parentModelName="Permit" />
                    </Box>
                </div>

            </div>
        </div>

    );
};

export default AnswerPermit;
