import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import AddIcon from "@material-ui/icons/Add";
import { Avatar, Box, Button, CircularProgress, IconButton, Paper, Typography, MenuList, MenuItem } from "@material-ui/core";
import useProfileIssueData from "../issue/useProfileIssueData";
import Api from "../../helpers/Api";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import MyPopOver from "../styled/CommonComponents/MyPopOver";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "23%",
        height: "230px",
        marginLeft: "20px",
        padding: "5px 15px 5px",
        borderRadius: "5px",
        "&:hover": {
            transform: "scale(1.03)",
        },
        [theme.breakpoints.down("md")]: {
            width: "31%",
        },
        [theme.breakpoints.down("sm")]: {
            width: "48%",
            marginLeft: "15px",
        },
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            marginLeft: "0px",
        },
    },
    headerCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "5px",
    },
    title: {
        fontSize: "18px",
        fontWeight: "510",
        textTransform: "capitalize",
    },
    icon: {
        color: theme.palette.primary.main,
        marginRight: "4px",
    },
    buttons: {
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
    },

}))

const PermitTemplateList = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const { permits, getData } = props;

    const onDelete = async (Id) => {
        try {
            const res = await Api.post("approvalPermit/deletePermitById", { permitId: Id });

            if (res?.status === "Success") {
                dispatch({
                    type: "AddApiAlert",
                    payload: {
                        success: true,
                        message: "Permit Deleted successfully",
                    },
                });
                getData();
            } else {
                dispatch({
                    type: "AddApiAlert",
                    payload: {
                        success: false,
                        message: "Permit Deleted failed",
                    },
                });
                console.error("Failed to delete permit:", res?.error);
            }
        } catch (error) {
            console.error("An error occurred while deleting permit:", error);
        }
    };


    return (
        <>
            <Paper className={classes.root} elevation={2} square>
                <div className={classes.headerCont}>
                    <Box display="flex" alignItems="center">
                        {/* <Avatar
                        // alt={title || "Untitled"}
    
                        // src={
                        //     displayPicture
                        //         ? displayPicture.thumbUrl
                        //             ? displayPicture.thumbUrl
                        //             : displayPicture.url
                        //         : " "
                        // }
                        style={{
                            width: "30px",
                            height: "30px",
                            marginRight: "10px",
                        }}
                    /> */}
                        {/* <h3 className={classes.title}>{title || "Untitled"}</h3> */}
                        <h3 className={classes.title}>{permits?.displayName || "Untitled"}</h3>
                    </Box>

                    <MyPopOver
                        closeOnClick={true}
                        appearContent={
                            <IconButton style={{ padding: "4px" }}>
                                <MoreHorizIcon />
                            </IconButton>
                        }
                        showContent={
                            <div>
                                <MenuList id="menu-list-grow">
                                    <MenuItem
                                        onClick={() => {
                                            history.push(`/permitapproval/${permits._id}/edit`);
                                        }}
                                    >
                                        <EditIcon className={classes.icon} /> Edit Permit
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => {
                                            onDelete(permits._id);
                                        }}
                                    >
                                        <DeleteIcon className={classes.icon} /> Delete Permit
                                    </MenuItem>
                                </MenuList>
                            </div>
                        }
                    />
                </div>
            </Paper>

        </>
    );
}
export default PermitTemplateList;