import React, { useState, useEffect } from 'react';
import BasicPrivateRoute from "../components/PrivateRoute/BasicPrivateRoute";
import { Switch, Route } from "react-router-dom";
import ApprovalHome from "../components/approval/ApprovalHome.jsx";
import EditApproval from "../components/approval/EditApproval.jsx";
import ApprovalRequest from "../components/approval/ApprovalRequest.jsx";
import ApprovalPage from "../components/approval/ApprovalPage.jsx";

import PermitAndapproval from "../components/permit&approval/profile.permitAndapproval"
import PermitApprovalEditManager from "../components/permit&approval/permitApproval.edit.manager"
import AnswerPermit from "../components/permit&approval/answer.permit.js"



const Routes = () => {
    return [
        <BasicPrivateRoute
        exact
        path="/approval/edit"
        noAppbar={true}
        useBothSide
        component={EditApproval}
      />,
  
      <BasicPrivateRoute
        exact
        path="/approval/:profileId"
        noAppbar={true}
        useBothSide
        component={ApprovalHome}
      />,
  
      <BasicPrivateRoute
        exact
        path="/approval/request/:requestId"
        noAppbar={true}
        useBothSide
        component={ApprovalRequest}
      />,
  
      <BasicPrivateRoute
        exact
        path="/approval/approve/:requestId"
        noAppbar={true}
        useBothSide
        component={ApprovalPage}
      />,

    //approval And Permit
    <BasicPrivateRoute
      exact
      path="/permitapproval/profile/:profileId"
      noAppbar={true}
      useBothSide={true}
      component={PermitAndapproval}
    />,
    <BasicPrivateRoute
      exact
      path="/permitapproval/:permitId/edit"
      noAppbar={true}
      useBothSide
      component={PermitApprovalEditManager}
    />,

    <BasicPrivateRoute
    exact
    path="/permitapproval/:permitId/answer"
    // editModeValue={true}
    noAppbar={true}
    useBothSide
    component={AnswerPermit}
  />,

    ];
}

export default Routes;