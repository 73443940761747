import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import Dashboard from "./DashBoard";
import Basic from "../basic";
import Layout from "../layout";
import RightBasic from "../RightBasic";
import Cookies from "universal-cookie";

const BasicPrivateRoute = ({
  component: Component,
  noAppbar,
  hideDrawer,
  useBothSide,
  useRightSide,
  path,
  modDrawer,
  ...rest
}) => {
  const cookies = new Cookies(null, { path: "/", domain: ".reallist.ai" });
  const { user } = useSelector((state) => state.auth);
  const userCookie = cookies.get("reallistUser");
  const CookieUserObj = typeof userCookie === 'object' ? userCookie : JSON.parse(userCookie || "null");
  const loggedIn = ((user && user?._id) || (CookieUserObj && CookieUserObj?._id)) ? true : false;

  return (
    <Route
      {...rest}
      key={path}
      render={(props) => {

        if (!loggedIn) {
          return <div>Loading...</div>;
        }

        return (
          <Layout
            noAppbar={noAppbar}
            hideDrawer={hideDrawer}
            modDrawer={modDrawer}
          >
            {useBothSide ? (
              <Component key={path} {...props} {...rest} />
            ) : useRightSide ? (
              <RightBasic>
                <Component key={path} {...props} {...rest} />
              </RightBasic>
            ) : (
              <Basic>
                <Component key={path} {...props} {...rest} />
              </Basic>
            )}
          </Layout>
        );
      }}
    />
  )
};

Layout.defaultProps = {
  hideDrawer: false,
  noAppbar: false,
  useRightSide: false,
  useBothSide: false,
  modDrawer: false,
};

Layout.propTypes = {
  /**
   * To display Drawer
   */
  hideDrawer: PropTypes.bool,

  /**
   * To display Appbar
   */
  noAppbar: PropTypes.bool,

  /*
   * To use right side of grid (used in forum)
   */
  useRightSide: PropTypes.bool,

  /*
   * To use full side of grid (used in forum)
   */
  useBothSide: PropTypes.bool,

  /*
   * To use moderator drawer of grid (used in forum)
   */
  modDrawer: PropTypes.bool,
};
export default BasicPrivateRoute;
