import React, { useState } from 'react'
import {
    Box,
    IconButton,
    Paper,
    TextField,
    Tooltip,
    makeStyles
} from '@material-ui/core'

import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteIcon from "@material-ui/icons/Delete";
import Api from "../../helpers/Api";
import QuestionType from './questionType.permit';
import QuestionOptions from './questionOptions.permit';
import { useDebounce } from 'react-use';

const useStyles = makeStyles((theme) => ({
    qustionlDiv: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // border: '1px solid red',
        width: "100%",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
        padding: "1rem",
        // marginBottom: "1rem",
        position: "relative",
        // overflow: "hidden",
        borderRadius: "15px",
        boxShadow: "rgba(0, 0, 0, 0.22) 4px 4px 4px",
        // height: "250px",
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },

    questionContain: {
        display: "flex",
        position: "relative",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
    },
    questionText: {
        width: "35%",
        margin: "0px 0px 0px",
        padding: "0px 0px 14px"
    },
    questionAnswer: {
        width: "100%",
        // border: '1px solid red',
        // borderBottom: "1px solid grey",
    },

    questionEdit: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        width: "100%",
        // border: "1px solid green",
        justifyContent: "flex-end",
    },

}));


const QuestionPermit = (props) => {
    const classes = useStyles();
    const { key, questionId, questions, createQuestion, deleteQuestion } = props

    const [type, setType] = useState(questions?.type || "Short Answer");
    const [questionText, setquestionText] = useState(questions?.questionText || "");
    const [questionImage, setquestionImage] = useState(questions?.questionImage || "");
    const [links, setquestionLinks] = useState(questions?.links || []);
    const [optionIds, setOptionIds] = useState([]);
    const [optionDict, setOptionDict] = useState([]);


    useDebounce(
        () => {
            if (questionId) {
                updateApi({
                    _id: questionId,
                    type,
                    questionText,
                    questionImage,
                    links,
                });
            }
        },
        100,
        [type, questionText, questionImage, links]
    );

    const updateApi = async (question) => {
        const res = await Api.post("question/update", question);
    };

    return (
        <Box
            style={{
                width: "100%",
                minWidth: "100%",
                height: "100%",
                marginBottom: "1rem",
            }}

        >
            <Paper className={classes.qustionlDiv} variant="outlined">
                <div className={classes.questionContain}>

                    <TextField
                        size="small"
                        //   variant="filled"
                        label="Question"
                        className={classes.questionText}
                        value={questionText}
                        onChange={(e) => setquestionText(e.target.value)}
                    // disabled={!isActive}
                    />
                    <QuestionType setType={setType} type={type} />
                </div>
                <div className={classes.questionAnswer}>
                    <QuestionOptions
                        type={type}
                        optionIds={optionIds}
                        setOptionIds={setOptionIds}
                        optionDict={optionDict}
                        setOptionDict={setOptionDict}
                    />
                </div>

                <div className={classes.questionEdit}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            width: "15%",
                        }}
                    >
                        <Tooltip title="Add new">
                            <IconButton
                                aria-label="Add"
                                onClick={() => {
                                    // onAdd(index);
                                    createQuestion();
                                }}
                            >
                                <AddCircleOutlineIcon />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Delete">
                            <IconButton
                                aria-label="Delete"
                                onClick={() => {
                                    deleteQuestion(questionId);
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>

            </Paper>
        </Box>
    )
}

export default QuestionPermit