import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
    useHistory,
    useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { getRequestsForApprovals } from "./api.call";
import { Paper, Avatar, Button } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useDebounce } from "react-use";
import moment from "moment";
import Chip from '@material-ui/core/Chip';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { 
    operationsLink, 
    estimationLink, 
    planningGetLink, 
    accountingLink,
    handleRedirect 
} from '../../helpers/redirectHelper';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100vh",
        backgroundColor: "white",
        overflow: "hidden",
    },
    chartsAndTablesCont: {
        width: "100%",
        height: "100%"
    },
    tableContainer: {
        width: "100%",
        height: "calc(100% - 50px)",
        "& tr": {
            height: "45px"
        },
        "& table": {
            tableLayout: "auto"
        }
    },
    paginationCont: {
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid #d2d1d1",
    },
}));

const ApprovalRequests = ({ profileId, filterObj, setLoader }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const classes = useStyles();
    const { user } = useSelector((state) => state.auth);

    const [requests, setRequests] = useState([])
    const [totalPages, setTotalPages] = useState(0)
    const [curPage, setCurPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(20)
    const [changeState, setChangeState] = useState()

    const getAllRequests = async () => {
        if (profileId) {
            setLoader(true)
            await getRequestsForApprovals({
                profileId,
                filterObj,
                limit: pageLimit,
                page: curPage - 1
            })
                .then((data) => {
                    console.log(data)
                    setRequests(data?.approvalRequests || [])
                    let locTotalPage = Math.ceil((data?.count || 0) / pageLimit);
                    setTotalPages(locTotalPage)
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    setLoader(false)
                })
        }
    }

    useDebounce(() => {
        getAllRequests()
    }, 1000, [curPage, profileId, changeState])

    useEffect(() => {
        setCurPage(1)
        setChangeState(!changeState)
    }, [filterObj, profileId])

    const getTitle = (parent, parentModelName) => {
        switch (parentModelName) {
            case "OpsOrder":
                return parent?.name;
            case "BudgetCost":
                return parent?.title;
            case "Transaction":
                return `${parent?.type}-${parent?.txNo}`;
            case "TxTemplate":
                return `${parent?.type} Templete-${parent?.txNo}`;
            case "FinancialCertificate":
                return `Certificate-${parent?.certificateNumber}`;
            case "SiteObservation":
                return parent?.title;
        }
    }

    const getType = (parent, parentModelName) => {
        switch (parentModelName) {
            case "OpsOrder":
                return parent?.type;
            case "BudgetCost":
                return "Budget Cost";
            case "Transaction":
                return parent?.type;
            case "TxTemplate":
                return `${parent?.type} Templete`;
            case "FinancialCertificate":
                return `Financial Certificate`;
            case "SiteObservation":
                return `Site Observation`;
        }
    }

    const redirect = (parent, parentModelName) => {
        let url = "";
        switch (parentModelName) {
            case "OpsOrder":
                url = operationsLink(`/procurements/ops-order/${parent?._id}/edit`);
                break;
            case "BudgetCost":
                url = estimationLink(`/budget-analysis/budget-cost/edit/${parent?._id}`);
                break;
            case "Transaction":
                if (parent?.type === "Invoice") {
                    url = accountingLink(`/finance/${parent?.firstPartyWallet}/invoice/edit/${parent?._id}`);
                } else if (parent?.type === "Bill") {
                    url = accountingLink(`/finance/${parent?.firstPartyWallet}/bill/edit/${parent?._id}`);
                } else if (parent?.type === "DebitNote") {
                    url = accountingLink(`/finance/${parent?.firstPartyWallet}/debit-note/edit/${parent?._id}`);
                } else if (parent?.type === "CreditNote") {
                    url = accountingLink(`/finance/${parent?.firstPartyWallet}/credit-note/edit/${parent?._id}`);
                }
                break;
            case "TxTemplate":
                if (parent?.type === "Invoice") {
                    url = accountingLink(`/finance/${parent?.firstPartyWallet}/invoice-generator/edit/${parent?._id}`);
                } else if (parent?.type === "Bill") {
                    url = accountingLink(`/finance/${parent?.firstPartyWallet}/bill-generator/edit/${parent?._id}`);
                }
                break;
            case "FinancialCertificate":
                url = accountingLink(`/finance/${parent?.walletId}/workorder-certificate/edit/${parent?._id}`);
                break;
            case "SiteObservation":
                url = planningGetLink(`/observation/edit/${parent?._id}`);
                break;
        }
        handleRedirect(url, history);
    }


    return (
        <Paper className={classes.chartsAndTablesCont} elevation={2} >
            <TableContainer className={classes.tableContainer}>
                <Table stickyHeader aria-label="sticky table" size={'small'}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" >
                                Title
                            </TableCell>
                            <TableCell align="left" >
                                Type
                            </TableCell>
                            <TableCell align="left" >
                                Stage
                            </TableCell>
                            <TableCell align="left" >
                                Template
                            </TableCell>
                            <TableCell align="left" style={{ maxWidth: "280px" }} >
                                Approver(s)
                            </TableCell>
                            <TableCell align="left" >
                                Created By
                            </TableCell>
                            <TableCell align="right" >
                                Created At
                            </TableCell>
                            <TableCell align="center" >
                                Link
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {requests && requests.length > 0 && requests.map((dataRow) => {
                            return (
                                <TableRow>
                                    <TableCell align="left" >
                                        {getTitle(dataRow?.parent, dataRow?.parentModelName)}
                                    </TableCell>
                                    <TableCell align="left" >
                                        {getType(dataRow?.parent, dataRow?.parentModelName)}
                                    </TableCell>
                                    <TableCell align="left" >
                                        {dataRow?.parent?.status}
                                    </TableCell>
                                    <TableCell align="left" >
                                        {dataRow?.approvalTemplate?.title}
                                    </TableCell>
                                    <TableCell align="left" style={{ maxWidth: "280px" }} >
                                        {dataRow?.canAccessAnyParticipants ? "Anyone Can Approve" : (<>{
                                            dataRow.shared.map((s) => (
                                                <Chip
                                                    size="small"
                                                    avatar={
                                                        <Avatar
                                                            alt={s?.parent?.displayName}
                                                            src={s?.parent?.displayPicture?.url}
                                                        />
                                                    }
                                                    label={s?.parent?.displayName}
                                                />
                                            ))
                                        }</>)}
                                    </TableCell>
                                    <TableCell align="left" >
                                        {dataRow?.createdBy?.parent?.displayName}
                                    </TableCell>
                                    <TableCell align="right" >
                                        {moment(dataRow?.createdAt).format("DD/MMM/YYYY")}
                                    </TableCell>
                                    <TableCell align="center" >
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                            startIcon={<OpenInNewIcon />}
                                            onClick={() => {
                                                redirect(dataRow?.parent, dataRow?.parentModelName)
                                            }}
                                        >
                                            Request Details
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className={classes.paginationCont} >
                <Pagination
                    count={totalPages}
                    page={curPage}
                    color="primary"
                    siblingCount={0}
                    onChange={(event, value) => { setCurPage(value) }}
                />
            </div>
        </Paper>
    );
};

export default ApprovalRequests;