import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useDebounce } from "react-use";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { Box, Button, CircularProgress, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, TextField, Typography, Slide, Slider, Card, CardContent, Checkbox, InputBase, Tooltip, Switch, Grid } from "@material-ui/core";
import useProfileIssueData from "../issue/useProfileIssueData";
import Api from "../../helpers/Api";
import CustomFileUploadButton from "../file/Uploader/CustomFileUploadButton";
import ShareIconBtn from "../share/share.icon.btn";
import useShared from "../share/useShared";
import SharedList from "../share/sharedList";
import Divider from '@material-ui/core/Divider';
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import EditIcon from "@material-ui/icons/Edit";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import DeleteIcon from "@material-ui/icons/Delete";
import ApprovalCheckList from "./approval.checkList";

import PermitQuestion from "./question.permit";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    backgroundColor: "white",
  },
  centerDiv: {
    flex: 1,
    display: "flex",
    // flexDirection: "column",
    justifyContent: "center",
    // alignItems: "center",
    backgroundColor: "white",
    padding: "1rem 0",
    paddingTop: "1.5rem",
    paddingLeft: "1rem",
    overflow: "auto", // Add the overflow property for scrolling
    height: "43rem",
  },
  permitDiv: {
    flex: 1,
    paddingRight: '10px'
  },
  topBar: {
    width: "100%",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #ebeaea",
  },
  backArrow: {
    fontSize: "30px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
  barLeft: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  barRight: {
    display: "flex",
    marginRight: "1%",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiButton-label": {
      textTransform: "capitalize",
    },
  },
  headerTitle: {
    fontSize: "20px",
    fontWeight: "550",
    marginLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
      fontWeight: "510",
      marginLeft: "0px",
    },
  },
  categoryAndLocationTag: {
    display: "flex",
    justifyContent: "space-between",
    width: "50%",
    marginTop: "10px",
    padding: "5px 40px 10px",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  categoryOrLocationCont: {
    width: "100%",
    minHeight: "300px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginBottom: "15px",
    },
  },
  formControlPriority: {
    width: "10%",
  },
  formControl: {
    width: "20%",
    // marginLeft: "2%"
  },
  page1: {
    width: "100%",
    height: "100%",
    overflowY: "auto",
    padding: "20px 90px 50px",
    "&::-webkit-scrollbar": {
      display: "none"
    },
    scrollbarWidth: "none",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 20px 50px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "10px 10px 50px",
    },
  },
  page2: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: "0px",
    left: "0px",
    zIndex: "1000",
    backgroundColor: "white"
  },

  priorityCard: {
    width: "100%",
    minWidth: "100%",
    height: "100%",
    marginBottom: "1rem",
  },
  approvalDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // border: '1px solid red',
    width: "30%",
    [theme.breakpoints.down("sm")]: {
      width: "30%",
    },
    // padding: "1rem",
    // marginBottom: "1rem",
    position: "relative",
    // overflow: "hidden",
    borderRadius: "15px",
    boxShadow: "rgba(0, 0, 0, 0.22) 4px 4px 4px",
    // height: "250px",
    // overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  checkListDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // border: '1px solid red',
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    // padding: "1rem",
    // marginBottom: "1rem",
    position: "relative",
    // overflow: "hidden",
    borderRadius: "15px",
    boxShadow: "rgba(0, 0, 0, 0.22) 4px 4px 4px",
    // height: "250px",
    // overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },

  checkListTag: {
    display: "flex",
    justifyContent: "space-between",
    width: "50%",
    marginTop: "20px",
    // marginLeft: "10px",
    padding: "5px 20px 10px",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  checkListCont: {
    width: "100%",
    minHeight: "300px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginBottom: "15px",
    },
  },
  textInput: {
    // maxWidth: "24rem",
    width: "60%",
    borderWidth: "1px",
    backgroundColor: "#f1f1f1",
    color: "#424242",
    borderRadius: "11px",
    fontSize: 16,
    zIndex: 0,
  },

  checklistPaper: {
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "1%",
    display: "flex",
    width: "80%",
    border: "1px solid lightgrey",
    paddingRight: "9px",
    borderRadius: "17px",
    position: "relative",
  },
  checkListEdit: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    width: "100%",
    // border: "1px solid green",
    justifyContent: "flex-end",
  },
  checkedIcon: {
    position: "absolute",
    fontSize: "130%",
    top: 0,
    right: 0,
  },
  checkListContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    width: "100%",
  },
  questionPerTag: {
    display: "flex",
    justifyContent: "space-between",
    width: "50%",
    marginTop: "1%",
    // marginLeft: "10px",
    padding: "5px 20px 10px",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  questionPerCont: {
    width: "100%",
    minHeight: "300px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginBottom: "15px",
    },
  },

}));


const approvalStatuses = [
  "Pending",
  "Clarification",
  "Deferred",
  "Approved",
  "Rejected"
]

const EditApprovalModal = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);
  const {
    setOpenEditView,
    permitId,
    approvalId,
    templatePermit
  } = props;
  const dispatch = useDispatch();


  const [approvalData, setApprovalData] = useState({});
  const [displayName, setDisplayName] = useState("");
  const [description, setDescription] = useState("");
  const [dueDate, setDueDate] = useState(new Date());
  const [priority, setPriority] = useState(0);
  const [approvalStatus, setApprovalStatus] = useState("");
  const [templateApproval, setTemplateApproval] = useState(templatePermit || false);

  const [checklist, setChecklist] = useState([]);
  const [questions, setQuestions] = useState([]);

  // const oldShared = approvalData?.shared?.map((profile) => profile?._id) || [];
  const [shared, setShared] = useState([]);

  const getApprovalById = async () => {
    try {
      const res = await Api.post("approvalPermit/getApprovalById", {
        approvalId: approvalId,
      });
      if (res?.data) {
        setApprovalData(res?.data);
        // setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (approvalId) {
      getApprovalById();
    }
  }, []);

  useEffect(() => {
    setDisplayName(approvalData?.displayName || "");
    setDescription(approvalData?.description || "");
    setDueDate(approvalData?.dueDate || new Date());
    setPriority(approvalData?.priority || 0);
    setApprovalStatus(approvalData?.status || "");
    setChecklist(approvalData?.checklist || []);
    setQuestions(approvalData?.questions || []);
    setTemplateApproval(templatePermit || approvalData?.isTemplate);
    setShared(approvalData?.shared?.map((profile) => profile?._id) || [])
  }, [approvalData]);


  // const oldassigned = permit?.assigned?.map((profile) => profile) || [];
  // const [assigned, setAssigned] = useState(oldassigned);
  const [isPrivate, setPrivate] = useState(false);

  const sharedProps = useShared({
    initShared: shared,
    initAssigned: [],
  });

  var { assignButton, assigness, sharedPeoples } = SharedList(
    sharedProps,
    isPrivate,
    setPrivate
  );
  //shared part

  const handleChange = (event, newValue) => {
    setPriority(newValue);
  }

  const handleChangeSave = async () => {

    const Id = approvalData?._id
    const obj = {
      isTemplate: templateApproval,
      displayName: displayName,
      description: description,
      priority: priority,
      dueDate: dueDate,
      status: approvalStatus,
      shared: sharedProps?.shared,
    }
    const hisData = {
      history: [{
        status: approvalStatus,
        timestamp: new Date(),
        user: user._id,
        profile: user.profile
      }]
    }
    const res = await Api.post("approvalPermit/updateApproval", { approvalId: Id, updateData: obj, historyData: hisData });

    const data = res?.data;
    if (data?._id) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Approval Updated successfully",
        },
      });
      getApprovalById();
      // history.goBack();
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Approval Updated failed",
        },
      });
    }
  };

  const AddCheckList = async () => {

    const obj = {
      parentModelName: "Task",
      text: '',
      description: '',
    }
    const res = await Api.post("checklist/create", { type: 'Approval', modelId: approvalData?._id, taskData: obj });

    const data = res?.data;
    if (res?.status === "success") {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Add CheckList successfully",
        },
      });
      getApprovalById();
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "CheckList Added failed",
        },
      });
    }
  };


  const DeleteCheckList = async (Id) => {

    const res = await Api.post("checklist/delete", { type: 'Approval', modelId: approvalData?._id, taskId: Id });

    const data = res?.data;
    if (res?.status === "success") {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Delete CheckList successfully",
        },
      });
      getApprovalById();
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "CheckList Delete failed",
        },
      });
    }
  };

  //Questions
  const createQuestion = async () => {

    const questionObj = {
      type: "Short Answer",
      profile: user?.profile,
      questionText: "",
    }

    const res = await Api.post("question/addQuestion", { type: "Approval", modelId: approvalData?._id, questionData: questionObj });

    if (res?.data) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Question Add successfully",
        },
      });
      getApprovalById();
    }
    else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Question Add failed",
        },
      });
    }
  };

  const deleteQuestion = async (Id) => {

    const obj = {
      questionId: Id,
      type: "Approval",
      modelId: approvalData?._id,
    }
    const res = await Api.post("question/removeQuestion", obj);
    if (res?.status === "success") {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Question Delete successfully",
        },
      });
      getApprovalById();
    }
    else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Question Delete failed",
        },
      });
    }
  };
  //Questions

  return (
    <div className={classes.root}>
      {/* <div className={classes.page1}> */}
      <div className={classes.topBar}>
        <div className={classes.barLeft}>
          <IconButton
            onClick={() => {
              // history.goBack();
              setOpenEditView(false);
            }}
          >
            <KeyboardBackspaceIcon className={classes.backArrow} />
          </IconButton>

          <Typography className={classes.headerTitle}>
            {/* {permit?.displayName || "Untitled"} */}
            Edit Approval
          </Typography>
        </div>
        <div className={classes.barRight}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleChangeSave}
          >
            Save
          </Button>
        </div>
      </div>
      <div className={classes.centerDiv}>

        <div className={classes.permitDiv}>

          {/* <div style={{ display: 'flex', justifyContent: "space-between", alignitems: "stretch" }}>
            <Typography> Approval</Typography>

            <Typography component="div">
              <Grid component="label" container alignItems="center" spacing={1}>
                <Typography> Template:</Typography>
                <Grid item>NO</Grid>
                <Grid item>
                  <Switch
                    checked={templateApproval}
                    onChange={handleChangeTemplate}
                    color="primary"
                    name="checkedB"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </Grid>
                <Grid item>YES</Grid>
              </Grid>
            </Typography>
          </div> */}
          <Paper style={{ width: "90%" }} elevation={0}>
            <div style={{ padding: "1rem 0" }}>

              {sharedProps?.shareDrawer}

              <div>


                <Box className={classes.priorityCard}>
                  <Typography id="discrete-slider-custom" style={{ fontWeight: "bold" }} gutterBottom>
                    Priority
                  </Typography>
                  <Paper className={classes.approvalDiv} variant="outlined">
                    <Slider
                      style={{ width: "80%" }}
                      value={priority}
                      onChange={handleChange}
                      aria-labelledby="discrete-slider-always"
                      step={1}
                      min={1}
                      max={10}
                      marks={[
                        { value: 1, label: '1' },
                        { value: 2, label: '2' },
                        { value: 3, label: '3' },
                        { value: 4, label: '4' },
                        { value: 5, label: '5' },
                        { value: 6, label: '6' },
                        { value: 7, label: '7' },
                        { value: 8, label: '8' },
                        { value: 9, label: '9' },
                        { value: 10, label: '10' },
                      ]}
                      valueLabelDisplay="on"
                      getAriaValueText={(value) => `${value}`}
                    />
                    {/* <h4
                      style={{
                        padding: "2px 0px",
                        borderRadius: "2px",
                        fontWeight: "normal",
                      }}
                    >
                      Priority
                    </h4> */}
                  </Paper>
                </Box>

              </div>


              <div style={{ display: "flex", alignitems: "stretch" }}>

                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">Approval Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={approvalStatus}
                    onChange={(e) => setApprovalStatus(e.target.value)}
                    label="Approval Status"
                  >
                    {approvalStatuses.map((val, index) => (
                      <MenuItem key={index} value={val}>{val}</MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <div style={{ marginTop: "0.5%", marginLeft: "2%" }}>
                    <InputLabel>Due Date</InputLabel>
                    <KeyboardDatePicker
                      id="date-picker-dialog-2"
                      format="dd/MM/yyyy"
                      value={dueDate}
                      onChange={(e) => setDueDate(e)}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    // Add left margin
                    />
                  </div>
                </MuiPickersUtilsProvider>

              </div>

              <TextField
                // label="Name"
                placeholder={displayName === "" && "Name"}
                type="text"
                variant="outlined"
                // margin="normal"
                style={{ width: "40%", marginTop: "1%" }}
                onChange={(e) => setDisplayName(e.target.value)}
                value={displayName === "" ? approvalData.displayName : displayName}
              />

              <div style={{ display: "flex", alignItems: "center", marginTop: "1%" }}>
                <TextField
                  style={{ width: "50%" }}
                  id="outlined-multiline-static"
                  placeholder={description === "" && "Description"}
                  multiline
                  variant="outlined"
                  rows={4}
                  value={description === "" ? approvalData.description : description}
                  onChange={(e) => setDescription(e.target.value)}
                />

                <Box style={{ marginLeft: "2%" }}>
                  <Typography
                    style={{
                      fontSize: "1rem",
                      fontFamily: "Inter",
                      fontWeight: "500",
                      lineHeight: "1.5",
                    }}
                  >
                    Upload a Picture for your Approval
                  </Typography>
                  <CustomFileUploadButton
                    showComponent={
                      <Button color="primary" variant="contained">
                        Upload
                      </Button>
                    }
                    fileNum={1}
                    givenFileTypes={["image"]}
                    givenMaxSize={26214400}
                    closeFunCall={() => { }}
                  />
                </Box>

              </div>


              <Box
                style={{
                  width: "100%",
                  minWidth: "100%",
                  height: "100%",
                  marginBottom: "1rem",
                }}
              >
                <Paper className={classes.checkListTag}>
                  <div className={classes.checkListCont}>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <h3 style={{ marginTop: "10px" }}>Check List</h3>
                    </div>
                    {checklist && checklist.length > 0 ? (
                      checklist.map((item, index) => (
                        <ApprovalCheckList
                          key={index}
                          index={index}
                          taskId={item}
                          AddCheckList={AddCheckList}
                          DeleteCheckList={DeleteCheckList}
                        />
                      ))
                    ) : (
                      <p>No checklist. Click Add to add checklist
                        <Button
                          style={{ marginLeft: "1%" }}
                          variant="outlined"
                          color="primary"
                          // className={classes.button}
                          endIcon={<AddCircleOutlineIcon />}
                          onClick={() => AddCheckList()}
                        >
                          Add
                        </Button> </p>
                    )}
                  </div>
                </Paper>

              </Box>

            </div>

            {templateApproval && <div>
              <Paper className={classes.questionPerTag}>
                <div className={classes.questionPerCont}>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      position: "sticky",
                    }}
                  >
                    <h3
                      style={{
                        color: "#10957d",
                        background: "rgba(16, 149, 125, 0.15)",
                        padding: "2px 10px",
                        borderRadius: "5px",
                        marginLeft: "0.3rem",
                        fontWeight: "normal",
                      }}
                    >
                      Questions
                    </h3>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        createQuestion(questions.length + 1);
                      }}
                      style={{ float: "right", margin: "1rem" }}
                    >
                      Create Question
                    </Button>
                  </div>

                  <div>
                    {questions && questions.length > 0 ? (
                      questions.map((item, index) => (
                        <PermitQuestion
                          key={index}
                          questionId={item._id}
                          questions={item}
                          createQuestion={createQuestion}
                          deleteQuestion={deleteQuestion}
                        />
                      ))
                    ) : (
                      <p>No Questions. Click Add to add Questions</p>
                    )}
                  </div>

                </div>
              </Paper>
            </div>}

            <div style={{ display: "flex", alignItems: "center" }}>
              <Paper className={classes.categoryAndLocationTag}>
                <div className={classes.categoryOrLocationCont}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <h3>Shared</h3>
                    <ShareIconBtn
                      open={sharedProps?.open}
                      setOpen={sharedProps?.setOpen}
                    />
                  </div>
                  <div>{sharedPeoples}</div>
                </div>
              </Paper>
            </div>

          </Paper>
        </div>
      </div>
    </div>
  );
};

export default EditApprovalModal;
