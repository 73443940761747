import {
    Avatar,
    Box,
    Checkbox,
    Chip,
    Divider,
    FormControl,
    IconButton,
    InputBase,
    InputLabel,
    makeStyles,
    MenuItem,
    Paper,
    Select,
    Switch,
    TextField,
    Tooltip,
    Typography,
} from "@material-ui/core";
import { SlPencil } from "react-icons/sl";
import React, { useEffect, useState } from "react";
import FilesViewer from "../file/Viewer/FilesViewer";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Api from "../../helpers/Api";
import { useDebounce } from "react-use";
import arrayToReducer from "../../helpers/arrayToReducer";
import { blue } from "@material-ui/core/colors";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
    approvalDiv: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // border: '1px solid red',
        width: "90%",
        [theme.breakpoints.down("sm")]: {
            width: "90%",
        },
        padding: "1rem",
        // marginBottom: "1rem",
        position: "relative",
        // overflow: "hidden",
        borderRadius: "15px",
        boxShadow: "rgba(0, 0, 0, 0.22) 4px 4px 4px",
        // height: "250px",
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    // bar: {
    //   position: "absolute",
    //   width: "10px",
    //   backgroundColor: "#407afd",
    //   height: "100%",
    //   top: "0px",
    //   left: "-6px",
    //   transition: "all 200ms ease-in",
    // },
    barActive: {
        left: "0px !important",
    },
    approvalTypeContainer: {
        // display: "flex",
        // flexDirection: "row",
        // justifyContent: "space-around",
        width: "100%",
        // border:"1px solid green"
    },
    approvalHeader: {
        textAlign: "left",
    },
    approvalText: {
        width: "35%",
        padding: "2px",
        marginTop: "0.5rem",
    },
    approvalAnswer: {
        width: "100%",
        // border: '1px solid red',
        // borderBottom: "1px solid grey",
    },
    approvalEdit: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        width: "100%",
        // border: "1px solid green",
        justifyContent: "flex-end",
    },


    formControl: {
        marginTop: "2%",
        width: "100%"
    },

    editBtn: {
        position: "absolute",
        fontSize: "130%",
        top: 0,
        right: 0,
    },
    textInput: {
        borderWidth: "1px",
        backgroundColor: "#f1f1f1",
        color: "#424242",
        borderRadius: "11px",
        fontSize: 16,
        zIndex: 0,
        padding: '8px 10px 7px',
    },
    textInput2: {
        borderWidth: "1px",
        backgroundColor: "#f1f1f1",
        color: "#424242",
        borderRadius: "11px",
        fontSize: 16,
        zIndex: 0,
        height: "5rem",
        padding: '8px 10px 7px',
    },

    switchContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '1rem',
    },
}));

const approvalStatuses = [
    "Pending",
    "Clarification",
    "Deferred",
    "Approved",
    "Rejected"
]

export default function Approval(props) {
    const classes = useStyles();

    const {
        index,
        approvalId,
        setApprovalId,
        isActive,

    } = props
    const [approval, setApproval] = useState([]);
    const [singleSelection, setSingleSelection] = useState(false); // State for the switch

    const handleSwitchChange = () => {
        setSingleSelection(!singleSelection);
    };
    const handleCheckboxChange = (index) => {
        if (!singleSelection) {
            // If switch is off, allow only single selection
            setApproval((prevApproval) => {
                const newApproval = prevApproval.map((item, i) => ({
                    ...item,
                    isChecked: i === index,
                }));
                return newApproval;
            });
        } else {
            // If switch is on, allow multiple selections
            setApproval((prevApproval) => {
                const newApproval = [...prevApproval];
                newApproval[index].isChecked = !newApproval[index].isChecked;
                return newApproval;
            });
        }
    };


    const getApprovalById = async () => {
        try {
            const res = await Api.post("approvalPermit/getProfileApprovalById", {
                approvalId: approvalId,
            });
            const data = res?.profile;
            if (data) {
                setApproval(data);
                // setLoader(true);
            }
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        if (approvalId) {
            getApprovalById();
        }
    }, [])

    // useEffect(() => {
    //     setQuestions(approval?.questions || [])
    //     setApprovalStatus(approval?.status || "");
    //     setChecklist(approval?.checklist || []);
    // }, [approval])

    return (
        <Box
            // component={"div"}
            style={{
                width: "100%",
                minWidth: "100%",
                height: "100%",
                marginBottom: "1rem",
            }}
        // onClick={() => {
        //     setActiveApproval(currentObj?._id);
        // }}
        >
            <Paper className={classes.approvalDiv} variant="outlined">
                <div
                    className={`${classes.bar} ${isActive ? classes.barActive : ""}`}
                ></div>
                <div
                    className={
                        isActive
                            ? `${classes.approvalTypeContainer}`
                            : `${classes.approvalHeader}`
                    }
                >
                    <div className={classes.switchContainer}>
                        <Typography variant="h6" component="span" style={{ marginRight: '1rem' }}>
                            Multiple Selection:
                        </Typography>
                        <Switch
                            checked={singleSelection}
                            onChange={handleSwitchChange}
                            color="primary"
                        />
                    </div>

                    {isActive ? (
                        <div>
                            {approval.map((value, index) => (
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Chip
                                        style={{ fontSize: '1rem' }}
                                        avatar={<Avatar style={{ fontSize: '1rem', width: '2rem', height: '2rem', }} alt="Image" src={value.displayPicture.url} />}
                                        label={value.displayName}
                                    // onDelete={handleDelete}
                                    />
                                    <Checkbox
                                        // defaultChecked

                                        color="primary"
                                        checked={value.isChecked || false}
                                        onChange={() => handleCheckboxChange(index)}
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    />
                                </div>

                            ))}
                        </div>

                    ) : (
                        <Typography variant="h6" component="p">
                            {/* {approvalText ? approvalText : "Approval"} */}
                            NO Shared
                        </Typography>
                    )}
                </div>


            </Paper>
        </Box>
    );
}
