import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

import AddIcon from "@material-ui/icons/Add";
import CancelIcon from "@material-ui/icons/Cancel";
import SearchIcon from "@material-ui/icons/Search";

import { Button, CircularProgress, IconButton, InputAdornment, TextField, Typography } from "@material-ui/core";
import useProfileIssueData from "../issue/useProfileIssueData";
import Api from "../../helpers/Api";
import arrayToReducer from "../../helpers/arrayToReducer";

import PermitTemplateCardList from "./permitTemplateCardList";
import SelectPermit from "./select.permit.dialog";
import TablePermit from "./table.permit";
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex"
    },
    header_container: {
        display: "flex",
        flexDirection: "column",
        "& h3": {
            fontWeight: "500",
            fontSize: "1.2rem",
            marginBottom: "1rem",
            marginTop: '1.5rem',
            marginLeft: '0.8rem'
        },
        marginBottom: "1rem",
    },
    subHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    searchTextBox: {
        padding: "12px 0px 12px 14px",
        background: "#F5F4F6",
        borderRadius: "8px",
        caretColor: "#2F80ED",
        fontWidth: "400",
        marginRight: "1rem",
    },
}))

const PermitApprovalTemplateList = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const [allpermits, setallpermits] = useState([]);
    const [allpermitsAnswer, setallpermitsAnswer] = useState([]);
    const [permitIds, setPermitIds] = useState([]);
    const [permitDict, setpermitDict] = useState({});
    const [searchQuery, setSearchQuery] = useState("");

    const { auth } = useSelector((state) => state);
    const { user } = auth;
    // const profile = user?.profile;
    const userId = user?._id;

    const getData = async () => {
        try {
            const res = await Api.post("approvalPermit/getPermitsByUser", {
                userId: userId,
            });
            const data = res?.data;
            const NoneTempdata = data.filter(item => item.isTemplate === false);
            const Tempdata = data.filter(item => item.isTemplate === true);
            if (Tempdata) {
                setallpermits(Tempdata);
                updateTemplateArray(Tempdata);
            }
            if (NoneTempdata) {
                // setLoading(false);
                setallpermitsAnswer(NoneTempdata);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const updateTemplateArray = (data) => {
        const filteredData = data.filter(item => item.isTemplate === true);
        const { newDict, idArr } = arrayToReducer(filteredData);
        setPermitIds(idArr);
        setpermitDict(newDict);
    };

    useEffect(() => {
        if (userId) {
            getData();
        }
    }, [])

    const createClonePermit = async (Id) => {
        const obj = {
            permitId: Id
        }
        const res = await Api.post("approvalPermit/clonePermitFromTemplate", obj);

        if (res?.data) {
            dispatch({
                type: "AddApiAlert",
                payload: {
                    success: true,
                    message: "Cloned Permit Create successfully",
                },
            });
            getData();
        }
        else {
            dispatch({
                type: "AddApiAlert",
                payload: {
                    success: false,
                    message: "Cloned Permit Create failed",
                },
            });
        }
    };

    const bulkDeleteClonePermit = async (Ids) => {
        if (Ids.length > 0) {
            const obj = {
                permitIds: Ids
            }
            const res = await Api.post("approvalPermit/bulkDeletePermits", obj);


            if (res?.status === "success") {
                dispatch({
                    type: "AddApiAlert",
                    payload: {
                        success: true,
                        message: "Cloned Permit Delete successfully",
                    },
                });
                getData();
            }
            else {
                dispatch({
                    type: "AddApiAlert",
                    payload: {
                        success: false,
                        message: "Cloned Permit Delete failed",
                    },
                });
            }
        }
    };

    return (
        <>
            <div className={classes.root}>
                {allpermits?.map((value, index) => (
                    <PermitTemplateCardList
                        key={index}
                        permits={value}
                        getData={getData}
                    />
                ))}
            </div>

            <div className={classes.header_container}>
                <h3>Permit</h3>
                <div className={classes.subHeader}>
                    <TextField
                        placeholder="Search by title"
                        type="text"
                        style={{ width: "80%" }}
                        className={classes.searchTextBox}
                        // onChange={(e) => setSearchQuery(e.target.value)}
                        // value={searchQuery}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            endAdornment: searchQuery && (
                                <IconButton
                                    onClick={() => setSearchQuery("")}
                                >
                                    <CancelIcon />
                                </IconButton>
                            ),
                            disableUnderline: true,
                        }}
                    />

                    <SelectPermit
                        allpermits={allpermits}
                        permitIds={permitIds}
                        permitDict={permitDict}
                        createClonePermit={createClonePermit}
                    // profile={profile}
                    />
                </div>
            </div>

            <TablePermit permitDict={allpermitsAnswer} bulkDeleteClonePermit={bulkDeleteClonePermit} />
        </>
    );
}
export default PermitApprovalTemplateList;