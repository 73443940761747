import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import AddIcon from "@material-ui/icons/Add";
import { Button, CircularProgress, IconButton, Typography } from "@material-ui/core";
import useProfileIssueData from "../issue/useProfileIssueData";
import Api from "../../helpers/Api";


import PermitApprovalTemplateList from "./permitApprovalTemplateList";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100vh",
        backgroundColor: "white",
    },
    topBar: {
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid #ebeaea",
    },
    backArrow: {
        fontSize: "30px",
        [theme.breakpoints.down("xs")]: {
            fontSize: "20px",
        },
    },
    barLeft: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    barRight: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& .MuiButton-label": {
            textTransform: "capitalize",
        },
    },
    headerTitle: {
        fontSize: "20px",
        fontWeight: "550",
        marginLeft: "10px",
        [theme.breakpoints.down("sm")]: {
            fontSize: "15px",
            fontWeight: "510",
            marginLeft: "0px",
        },
    },
    invoiceEditBody: {
        width: "100%",
        height: "calc(100% - 50px)",
        overflowX: "hidden",
        overflowY: "auto",
        padding: "0px 25px 100px",
        [theme.breakpoints.down("sm")]: {
            padding: "10px 10px 50px",
        },
    },
    loadingSty: {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    sectionTop: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid #d0cfcf",
        marginBottom: "15px",
        padding: "5px",
        paddingBottom: "10px",
        "& h3": {
            fontSize: "21px",
            fontWeight: "600",
            color: "#193B56",
        },
        [theme.breakpoints.down("sm")]: {
            "& h3": {
                fontSize: "18px",
                fontWeight: "600",
            },
        },
    },
    icon: {
        color: "#1684ea",
    },
    btnSty: {
        marginRight: "10px",
    },
}));

const PermitAndApproval = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { profileId } = useParams();
    const dispatch = useDispatch();
    const { auth } = useSelector((state) => state);
    const { user } = auth;
    // const profile = user?.profile;
    const userId = user?._id;

    const {
        loading,
    } = useProfileIssueData(profileId);

    const createPermitTemplate = async () => {
        const res = await Api.post("approvalPermit/createPermit", {
            isTemplate: true,
            user: userId,
            profile: profileId,
            shared: [profileId],
            // managers: [user?.profile],
        });

        const data = res?.data;
        if (data?._id) {
            dispatch({
                type: "AddApiAlert",
                payload: {
                    success: true,
                    message: "Permit created successfully",
                },
            });

            var path = "/permitapproval/" + data?._id + "/edit";
            history.push(path);
        } else {
            dispatch({
                type: "AddApiAlert",
                payload: {
                    success: false,
                    message: "Permit create failed",
                },
            });
            var path = "/permitapproval/profile/" + profileId;
            history.push(path);
        }
    };

    return (
        <div className={classes.root}>
            <div className={classes.topBar}>
                <div className={classes.barLeft}>
                    <IconButton
                        onClick={() => {
                            history.goBack();
                        }}
                    >
                        <KeyboardBackspaceIcon className={classes.backArrow} />
                    </IconButton>
                    <Typography className={classes.headerTitle}>
                        Permit And Approval
                    </Typography>
                </div>
                <div className={classes.barRight}></div>
            </div>

            <div className={classes.invoiceEditBody}>
                {loading ? (
                    <div className={classes.loadingSty}>
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        <div className={classes.sectionTop} style={{ marginTop: "10px" }}>
                            <h3>Permit Template</h3>
                            <div className={classes.barRight}>
                                <Button
                                    variant="contained"
                                    startIcon={<AddIcon className={classes.icon} />}
                                    onClick={() => {
                                        createPermitTemplate();
                                    }}
                                    className={classes.btnSty}
                                >
                                    Add
                                </Button>
                            </div>
                        </div>

                        <PermitApprovalTemplateList
                            profileId={profileId}
                        />

                    </>
                )}
            </div>
        </div>
    );
};

export default PermitAndApproval;
