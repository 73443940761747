import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { Box, Button, CircularProgress, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, TextField, Typography, Slide, Switch, Grid } from "@material-ui/core";
import useProfileIssueData from "../issue/useProfileIssueData";
import Api from "../../helpers/Api";
import CustomFileUploadButton from "../file/Uploader/CustomFileUploadButton";
import ShareIconBtn from "../share/share.icon.btn";
import useShared from "../share/useShared";
import SharedList from "../share/sharedList";
import Divider from '@material-ui/core/Divider';
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import EditIcon from "@material-ui/icons/Edit";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

// import Approvalindex from "./approval.index";
import Approvalindex from "./newApprova.index";

import EditApprovalModal from "./editApprovalModal";
import PermitQuestion from "./question.permit";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100vh",
        backgroundColor: "white",
    },
    centerDiv: {
        flex: 1,
        display: "flex",
        // flexDirection: "column",
        justifyContent: "center",
        // alignItems: "center",
        backgroundColor: "white",
        padding: "1rem 0",
        paddingTop: "1.5rem",
        paddingLeft: "1rem"
    },
    permitDiv: {
        flex: 1,
        paddingRight: '10px'
    },
    approvalDiv: {
        flex: 1,
    },
    topBar: {
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid #ebeaea",
    },
    backArrow: {
        fontSize: "30px",
        [theme.breakpoints.down("xs")]: {
            fontSize: "20px",
        },
    },
    barLeft: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    barRight: {
        display: "flex",
        marginRight: "1%",
        alignItems: "center",
        justifyContent: "space-between",
        "& .MuiButton-label": {
            textTransform: "capitalize",
        },
    },
    headerTitle: {
        fontSize: "20px",
        fontWeight: "550",
        marginLeft: "10px",
        [theme.breakpoints.down("sm")]: {
            fontSize: "15px",
            fontWeight: "510",
            marginLeft: "0px",
        },
    },
    categoryAndLocationTag: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        marginTop: "1%",
        padding: "5px 40px 10px",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
        },
    },
    categoryOrLocationCont: {
        width: "100%",
        minHeight: "300px",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            marginBottom: "15px",
        },
    },
    formControl: {
        marginTop: "2.5%",
        width: "20%"
    },
    page1: {
        width: "100%",
        height: "100%",
        overflowY: "auto",
        padding: "20px 90px 50px",
        "&::-webkit-scrollbar": {
            display: "none"
        },
        scrollbarWidth: "none",
        [theme.breakpoints.down("sm")]: {
            padding: "20px 20px 50px",
        },
        [theme.breakpoints.down("xs")]: {
            padding: "10px 10px 50px",
        },
    },
    page2: {
        position: "absolute",
        width: "100%",
        height: "100%",
        top: "0px",
        left: "0px",
        zIndex: "1000",
        backgroundColor: "white"
    },

    questionPerTag: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        marginTop: "1%",
        // marginLeft: "10px",
        padding: "5px 20px 10px",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
        },
    },
    questionPerCont: {
        width: "100%",
        minHeight: "300px",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            marginBottom: "15px",
        },
    },
}));

const permitTypes = [
    "Internal",
    "Government",
    "Client"
]
// const permitStatuses = [
//     "Open",
//     "In Progress",
//     "Closed"
// ]
const EditPermitApproval = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { permitId, setLoading } = props;
    const { user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    const [permit, setPermit] = useState(null);
    // const [approvalObj, setApprovalObj] = useState({});
    const [approvalId, setApprovalId] = useState("");

    const [displayName, setDisplayName] = useState("");
    const [descriptions, setDescription] = useState("");
    const [dueDate, setDueDate] = useState(new Date());
    const [expiryDate, setExpiryDate] = useState(new Date());
    const [permitType, setPermitType] = useState(permitTypes[0]);
    // const [permitStatus, setPermitStatus] = useState(permitStatuses[0]);
    const [templatePermit, setTemplatePermit] = useState(false);
    const [questions, setQuestions] = useState([]);

    const getPermitById = async () => {
        try {
            const res = await Api.post("approvalPermit/getPermitById", {
                permitId: permitId,
            });
            const data = res?.data;
            if (data) {
                setPermit(data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (permitId) {
            getPermitById();
        }
    }, [])

    useEffect(() => {
        setDisplayName(permit?.displayName || "");
        setDescription(permit?.description || "");
        setDueDate(permit?.dueDate || new Date());
        setExpiryDate(permit?.expiryDate || new Date());
        setPermitType(permit?.permitType || permitTypes[0]);
        // setPermitStatus(permit?.permitStatus || permitStatuses[0]);
        setTemplatePermit(permit?.isTemplate || false);
        setQuestions(permit?.questions || []);
    }, [permit])





    const [openEditView, setOpenEditView] = useState(false);

    //shared part
    const oldShared = permit?.shared?.map((profile) => profile) || [];
    const [shared, setShared] = useState(oldShared);
    // const oldassigned = permit?.assigned?.map((profile) => profile) || [];
    // const [assigned, setAssigned] = useState(oldassigned);
    const [isPrivate, setPrivate] = useState(false);

    const sharedProps = useShared({
        initShared: shared,
        initAssigned: [],
    });

    var { assignButton, assigness, sharedPeoples } = SharedList(
        sharedProps,
        isPrivate,
        setPrivate
    );
    //shared part

    //select menu part
    const handleChangeSelectType = (val) => {
        setPermitType(val);
    };
    // const handleChangeSelectStatus = (val) => {
    //     setPermitStatus(val);
    // };
    //select menu part

    //switch for Template
    // const handleChangeTemplate = () => {
    //     setTemplatePermit((prev) => !prev);
    // }
    //switch for Template

    const handleChangeSave = async () => {

        const Id = permit?._id
        const obj = {
            isTemplate: templatePermit,
            displayName: displayName,
            description: descriptions,
            permitType: permitType,
            dueDate: dueDate,
            expiryDate: expiryDate,
            // permitStatus: permitStatus,

        }
        const res = await Api.post("approvalPermit/updatePermitById", { PermitId: Id, updatePermit: obj });

        const data = res?.data;
        if (data?._id) {
            dispatch({
                type: "AddApiAlert",
                payload: {
                    success: true,
                    message: "Permit Updated successfully",
                },
            });
            getPermitById();
            // history.goBack();
        } else {
            dispatch({
                type: "AddApiAlert",
                payload: {
                    success: false,
                    message: "Permit Updated failed",
                },
            });
        }
    };


    //Questions
    const createQuestion = async () => {

        const questionObj = {
            type: "Short Answer",
            profile: user?.profile,
            questionText: "",
        }

        const res = await Api.post("question/addQuestion", { type: "Permit", modelId: permit?._id, questionData: questionObj });

        if (res?.data) {
            dispatch({
                type: "AddApiAlert",
                payload: {
                    success: true,
                    message: "Question Add successfully",
                },
            });
            getPermitById();
        }
        else {
            dispatch({
                type: "AddApiAlert",
                payload: {
                    success: false,
                    message: "Question Add failed",
                },
            });
        }
    };

    const deleteQuestion = async (Id) => {

        const obj = {
            questionId: Id,
            type: "Permit",
            modelId: permit?._id,
        }
        const res = await Api.post("question/removeQuestion", obj);
        if (res?.status === "success") {
            dispatch({
                type: "AddApiAlert",
                payload: {
                    success: true,
                    message: "Question Delete successfully",
                },
            });
            getPermitById();
        }
        else {
            dispatch({
                type: "AddApiAlert",
                payload: {
                    success: false,
                    message: "Question Delete failed",
                },
            });
        }
    };
    //Questions

    return (
        <div className={classes.root}>
            {/* <div className={classes.page1}> */}
            <div className={classes.topBar}>
                <div className={classes.barLeft}>
                    <IconButton
                        onClick={() => {
                            history.goBack();
                        }}
                    >
                        <KeyboardBackspaceIcon className={classes.backArrow} />
                    </IconButton>

                    <Typography className={classes.headerTitle}>
                        {/* {permit?.displayName || "Untitled"} */}
                        Edit Permit And Approval
                    </Typography>
                </div>
                <div className={classes.barRight}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleChangeSave}
                    >
                        Save
                    </Button>
                </div>
            </div>
            <div className={classes.centerDiv}>

                <div className={classes.permitDiv}>
                    <div style={{ display: 'flex', justifyContent: "space-between", alignitems: "stretch" }}>
                        <Typography> Permit</Typography>

                        {/* <Typography component="div">
                            <Grid component="label" container alignItems="center" spacing={1}>
                                <Typography> Template:</Typography>
                                <Grid item>NO</Grid>
                                <Grid item>
                                    <Switch
                                        checked={templatePermit}
                                        onChange={handleChangeTemplate}
                                        color="primary"
                                        name="checkedB"
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                                </Grid>
                                <Grid item>YES</Grid>
                            </Grid>
                        </Typography> */}
                    </div>
                    <Paper style={{ width: "90%" }} elevation={0}>
                        <div style={{ padding: "1rem 0" }}>

                            {sharedProps?.shareDrawer}

                            <div style={{ display: "flex", justifyContent: "space-between", alignitems: "stretch" }}>
                                <TextField
                                    label="Name"
                                    placeholder="Name"
                                    type="text"
                                    variant="outlined"
                                    margin="normal"
                                    style={{ width: "50%" }}
                                    onChange={(e) => setDisplayName(e.target.value)}
                                    value={displayName}
                                />

                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-outlined-label">Permit Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={permitType}
                                        onChange={(e) => handleChangeSelectType(e.target.value)}
                                        label="Permit Type"
                                    >
                                        {permitTypes.map((val, index) => (
                                            <MenuItem key={index} value={val}>{val}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                {/* <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-outlined-label">Permit Status</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={permitStatus}
                                        onChange={(e) => handleChangeSelectStatus(e.target.value)}
                                        label="Permit Status"
                                    >
                                        {permitStatuses.map((val, index) => (
                                            <MenuItem key={index} value={val}>{val}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl> */}

                            </div>

                            <div style={{ display: "flex", alignItems: "center", marginTop: "1%" }}>

                                <TextField
                                    style={{ width: "50%" }}
                                    id="outlined-multiline-static"
                                    label="Description"
                                    placeholder="Description"
                                    multiline
                                    variant="outlined"
                                    rows={4}
                                    value={descriptions}
                                    onChange={(e) => setDescription(e.target.value)}
                                />

                                <Box style={{ marginLeft: "2%" }}>
                                    <Typography
                                        style={{
                                            fontSize: "1rem",
                                            fontFamily: "Inter",
                                            fontWeight: "500",
                                            lineHeight: "1.5",
                                        }}
                                    >
                                        Upload a Picture for your Permit
                                    </Typography>
                                    <CustomFileUploadButton
                                        showComponent={
                                            <Button color="primary" variant="contained">
                                                Upload
                                            </Button>
                                        }
                                        fileNum={1}
                                        givenFileTypes={["image"]}
                                        givenMaxSize={26214400}
                                        closeFunCall={() => { }}
                                    />
                                </Box>

                            </div>


                            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "2%" }}>


                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div>
                                        <InputLabel>Issue Date</InputLabel>
                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="date-picker-dialog-2"
                                            format="dd/MM/yyyy"
                                            value={dueDate}
                                            onChange={(e) => setDueDate(e)}
                                            // onChange={handleChangeIssueDate}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                        // Add left margin
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>

                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div>
                                        <InputLabel>Expiry Date</InputLabel>
                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="date-picker-dialog-2"
                                            format="dd/MM/yyyy"
                                            value={expiryDate}
                                            onChange={(e) => setExpiryDate(e)}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                        // Add left margin
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>

                            </div>

                        </div>

                        {templatePermit === true && <div>
                            <Paper className={classes.questionPerTag}>
                                <div className={classes.questionPerCont}>

                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            position: "sticky",
                                        }}
                                    >
                                        <h3
                                            style={{
                                                color: "#10957d",
                                                background: "rgba(16, 149, 125, 0.15)",
                                                padding: "2px 10px",
                                                borderRadius: "5px",
                                                marginLeft: "0.3rem",
                                                fontWeight: "normal",
                                            }}
                                        >
                                            Questions
                                        </h3>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                createQuestion(questions.length + 1);
                                            }}
                                            style={{ float: "right", margin: "1rem" }}
                                        >
                                            Create Question
                                        </Button>
                                    </div>

                                    <div>
                                        {questions && questions.length > 0 ? (
                                            questions.map((item, index) => (
                                                <PermitQuestion
                                                    key={index}
                                                    questionId={item._id}
                                                    questions={item}
                                                    createQuestion={createQuestion}
                                                    deleteQuestion={deleteQuestion}
                                                />
                                            ))
                                        ) : (
                                            <p>No Questions. Click Add to add Questions</p>
                                        )}
                                    </div>

                                </div>
                            </Paper>
                        </div>}

                        <div>
                            <Paper className={classes.categoryAndLocationTag}>
                                <div className={classes.categoryOrLocationCont}>
                                    <div
                                        style={{
                                            width: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <h3>Shared</h3>
                                        <ShareIconBtn
                                            open={sharedProps?.open}
                                            setOpen={sharedProps?.setOpen}
                                        />
                                    </div>
                                    <div>{sharedPeoples}</div>
                                </div>
                            </Paper>

                        </div>

                    </Paper>
                </div>

                <Divider variant="middle" orientation="vertical" flexItem />

                <div className={classes.approvalDiv}>
                    <Approvalindex
                        permitId={permitId}
                        setApprovalId={setApprovalId}

                        // permitapprovalsArr={permitapprovalsArr}
                        // setApprovals={setApprovals}

                        setOpenEditView={setOpenEditView}
                    />
                </div>
            </div>
            {/* </div> */}


            <Slide direction="left" in={openEditView} mountOnEnter unmountOnExit>
                <div className={classes.page2} >
                    <EditApprovalModal
                        setOpenEditView={setOpenEditView}
                        // permitapprovalsArr={permitapprovalsArr}
                        // setApprovals={setApprovals}
                        permitId={permit?._id}
                        approvalId={approvalId}
                        templatePermit={templatePermit}
                    // approvalObj={approvalObj}
                    />
                </div>
            </Slide>

        </div>
    );
};

export default EditPermitApproval;
