import configObject from "../config/index";

const isProd = configObject.mode === 'prod';

export const planningGetLink = (path) => {
    return isProd ? `https://planning.reallist.ai${path}` : path;
}

export const accountingLink = (path) => {
    return isProd ? `https://accounts.reallist.ai${path}` : path;
}

export const estimationLink = (path) => {
    return isProd ? `https://estimation.reallist.ai${path}` : path;
}

export const operationsLink = (path) => {
    return isProd ? `https://operations.reallist.ai${path}` : path;
}

export const handleRedirect = (url, history) => {
    if (isProd) {
        window.open(url, '_blank');
    } else {
        history.push(url);
    }
}