import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";

import AddIcon from "@material-ui/icons/Add";
import { useMediaQuery, Avatar, Box, Button, CircularProgress, IconButton, Paper, Typography, MenuList, MenuItem, Stepper, Step, StepLabel, StepContent } from "@material-ui/core";
import arrayToReducer from "../../helpers/arrayToReducer";
import Api from "../../helpers/Api";

import Approval from "./approvals";

import { SlPencil } from "react-icons/sl";
import CreateRoundedIcon from '@material-ui/icons/CreateRounded';
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

const useStyles = makeStyles((theme) => ({
    formContainer: {
        border: "1px solid rgba(0, 0, 0, 0.23)",
        borderRadius: "5px",
        overflowY: "scroll",
        maxHeight: "2000px",
        overflowX: "hidden",
        alignText: "left",
        "&::-webkit-scrollbar": {
            display: "none",
        },
        paddingBottom: "1rem",
    },
    active: {
        backgroundColor: "#007bff",
    },
    container: {
        width: "75%",
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    timeline_container: {
        width: "100%",
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },


    text: {
        fontSize: 20,
        fontWeight: "bold",
        margin: "0rem 1rem",
        fontColor: "#111111",
        marginLeft: '2%'
    },
    actionsContainer: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        display: 'flex',
        alignItems: 'center'
    },
    button: {
        marginRight: theme.spacing(1),
    },
    editBtn: {
        // position: "absolute",
        fontSize: "130%",
        top: 0,
        right: 0,
    },
}))

const NewApprovalindex = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();
    const dispatch = useDispatch();

    const {
        permitId,
        // permitapprovalsArr,

        setApprovalId,

        setOpenEditView,
    } = props;

    const [loading, setLoading] = useState(false);

    const { auth } = useSelector((state) => state);
    const { user } = auth;
    // const profile = user?.profile;
    const userId = user?._id;

    const [columns, setColumns] = useState([]);
    const [approvalIds, setApprovalIds] = useState([]);
    // const [activeApproval, setActiveApproval] = useState(0);

    const [approvalState, setApprovalState] = useState({});
    const [timeLineData, setTimeLineData] = useState([]);

    const isSmall = useMediaQuery(theme.breakpoints.down("md"));
    const isMedium = useMediaQuery(theme.breakpoints.down("md"));

    const [approvals, setApprovals] = useState([]);

    //stepper
    const [activeStep, setActiveStep] = useState(0);
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleReset = () => {
        setActiveStep(0);
    };

    //stepper

    const getApprovalsByPermit = async () => {
        const obj = {
            permitId: permitId,
        };
        const res = await Api.post("approvalPermit/getApprovalByPermitId", obj);
        if (res?.status === 'success') {
            const newData = res?.data.filter((istemp) => istemp.isTemplate === true)
            setApprovals(newData);
        }
        setLoading(false);
    };

    useEffect(() => {
        getApprovalsByPermit();
    }, [])


    const createApprovalDefault = async (index) => {
        const obj = {
            index: index,
            permitId: permitId,
            approvalobj: {
                isTemplate: true,
                user: userId,
                permit: permitId,
                parentModelName: "Approval",
                status: "Pending",
                priority: 1
            },
        };
        const res = await Api.post("approvalPermit/createApprovalDefalt", obj);
        if (res?.data) {
            getApprovalsByPermit();
        }
        setLoading(false);
    };


    useEffect(() => {
        if (approvals?.length > 0) {
            const { newDict, idArr } = arrayToReducer(approvals);
            setApprovalIds(idArr);
            // setApprovalDict(newDict);
            setTimeLineData(approvals);
            // setActiveApproval(idArr[0] ? idArr[0] : 0);
        }
    }, [approvals]);

    const approvalDelete = async (Id) => {
        const obj = {
            permitId: permitId,
            approvalId: Id
        };
        const res = await Api.post("approvalPermit/deleteApproval", obj);
        if (res?.status === 'success') {
            getApprovalsByPermit();
        }
        setLoading(false);
    };

    return (
        <div>
            {loading ? (
                <CircularProgress />
            ) : (
                <>
                    {approvalIds?.length > 0 ? (
                        <>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    position: "sticky",
                                }}
                            >
                                <h3
                                    style={{
                                        color: "#10957d",
                                        background: "rgba(16, 149, 125, 0.15)",
                                        padding: "2px 10px",
                                        borderRadius: "5px",
                                        marginLeft: "1rem",
                                        fontWeight: "normal",
                                    }}
                                >
                                    Approval
                                </h3>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        createApprovalDefault(approvalIds?.length);
                                    }}
                                    style={{ float: "right", margin: "1rem" }}
                                >
                                    Create Approval
                                </Button>
                            </div>

                            <div className={classes.timeline_container}>
                                <Stepper activeStep={activeStep} orientation="vertical">
                                    {timeLineData.map((currentObj, index) => {
                                        return (
                                            <Step key={index}>
                                                <StepLabel>
                                                    <div style={{ display: 'flex', justifyContent: "center" }}>
                                                        <Typography variant="h5" className={classes.text}>
                                                            Title
                                                            <Typography style={{ width: '20rem' }}>{currentObj?.displayName}</Typography>
                                                        </Typography>
                                                    
                                                        <IconButton
                                                            className={classes.editBtn}
                                                            onClick={() => {
                                                                setApprovalId(currentObj?._id);
                                                                setOpenEditView(true);
                                                            }}
                                                        >
                                                            <CreateRoundedIcon />
                                                        </IconButton>
                                                        {/* <IconButton
                                                            aria-label="add"
                                                            onClick={() => {
                                                                // if (index + 1 == approvalIds?.length) {
                                                                //   createApproval(-1);
                                                                // } else {
                                                                // createApproval(index + 1);
                                                                // }
                                                                createApprovalDefault(index + 1);
                                                            }}
                                                        >
                                                            <AddCircleOutlineIcon />
                                                        </IconButton> */}
                                                        <IconButton
                                                            aria-label="delete"
                                                            onClick={() => {
                                                                approvalDelete(currentObj._id);
                                                            }}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </div>
                                                </StepLabel>
                                                <StepContent>
                                                    <div style={{ marginLeft: "1rem" }}>
                                                        <Approval
                                                            index={index}
                                                            approvalId={currentObj._id}
                                                            // setApprovalId={setApprovalId}

                                                            setOpenEditView={setOpenEditView}
                                                            isActive={true}
                                                        />
                                                    </div>
                                                    <div className={classes.actionsContainer}>
                                                        <div>
                                                            <Button
                                                                disabled={activeStep === 0}
                                                                onClick={handleBack}
                                                                className={classes.button}
                                                            >
                                                                Back
                                                            </Button>
                                                            <Button
                                                                variant="contained"
                                                                color="inherit"
                                                                onClick={handleNext}
                                                                className={classes.button}
                                                            >
                                                                {activeStep === approvals.length - 1 ? 'Finish' : 'Next'}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </StepContent>
                                            </Step>
                                        )
                                    })}
                                </Stepper>
                            </div>
                        </>
                    ) : (
                        <>
                            <Typography>
                                {" "}
                                Approval is empty. Start by adding any number of Approvalss
                            </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    createApprovalDefault(approvalIds?.length);
                                }}
                            >
                                Create Approval
                            </Button>
                        </>
                    )}
                </>
            )}
        </div>
    );
}

export default NewApprovalindex;
