import React, { useState } from "react";
import {
  withStyles,
  makeStyles,
  FormControl,
  Select,
  MenuItem,
  ListItemIcon,
  Popover,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  ShortText as ShortTextIcon,
  Subject as SubjectIcon,
  RadioButtonChecked as RadioButtonCheckedIcon,
  CheckCircle as CheckCircleIcon,
  ArrowDropDownCircle as ArrowDropDownCircleIcon,
  CloudUpload as CloudUploadIcon,
  Event as EventIcon,
  Schedule as ScheduleIcon,
  LinearScale as LinearScaleIcon,
  DragIndicator as DragIndicatorIcon,
  GridOn as GridOnIcon,
  Functions as FunctionsIcon,
  Apps as AppsIcon,
} from "@material-ui/icons";
import LinkIcon from "@material-ui/icons/Link";
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import ApartmentIcon from '@material-ui/icons/Apartment';
import DescriptionIcon from '@material-ui/icons/Description';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import FeaturedVideoIcon from '@material-ui/icons/FeaturedVideo';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Divider from '@material-ui/core/Divider';


const useStyles = makeStyles((theme) => ({
  selectForm: {
    width: "40%",
    marginRight: "1rem",
  },
  selectComponent: {
    borderRadius: "10px",
  },
  button: {
    backgroundColor: 'white',
    border: '1px solid #ccc',
    borderRadius: '4px',
    padding: '8px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  arrowIcon: {
    marginLeft: "1px",
  },
}));


const options = [
  //General
  { sl: 0, value: "Short Answer", icon: <ShortTextIcon /> },
  { sl: 1, value: "Long Answer", icon: <SubjectIcon /> },
  { sl: 2, value: "Multiple Choice", icon: <RadioButtonCheckedIcon /> },
  { sl: 3, value: "Boolean", icon: <CheckCircleIcon /> },
  { sl: 4, value: "Dropdown", icon: <ArrowDropDownCircleIcon /> },
  { sl: 5, value: "File Upload", icon: <CloudUploadIcon /> },
  { sl: 6, value: "Link", icon: <LinkIcon /> },
  { sl: 7, value: "Date", icon: <EventIcon /> },
  { sl: 8, value: "Time", icon: <ScheduleIcon /> },
  { sl: 9, value: "Linear Scale", icon: <LinearScaleIcon /> },
  { sl: 10, value: "Number", icon: <FunctionsIcon /> },
//   { sl: 11, value: "Multiple Choice Grid", icon: <DragIndicatorIcon /> },
//   { sl: 12, value: "Checkbox Grid", icon: <AppsIcon /> },
//   { sl: 13, value: "Form Table", icon: <GridOnIcon /> },
  //Platform
  { sl: 11, value: "Contact", icon: <ContactPhoneIcon /> },
  { sl: 12, value: "Organization", icon: <ApartmentIcon /> },
  { sl: 13, value: "Project", icon: <DescriptionIcon /> },
  { sl: 14, value: "User", icon: <PersonOutlineIcon /> },
  { sl: 15, value: "Ticket", icon: <ConfirmationNumberIcon /> },
  { sl: 16, value: "Template", icon: <FeaturedVideoIcon /> },
];

const QuestionType = (props) => {
  const classes = useStyles();
  const { setType, type } = props;

  const typeToValue = (value) => {
    const valueToTypeMap = options.filter((e) => e.value === value)
    return valueToTypeMap[0];
  };

  const valueToType = (value) => {
    const valueToTypeMap = options.filter((e) => e.value === value)
    return valueToTypeMap[0].value;
  };

  // const [val, setVal] = useState(typeToValue(type) || 0);

  const [selectedOption, setSelectedOption] = useState(typeToValue(type) || options[0]);

  const handleChange = (newValue) => {
    setSelectedOption(newValue);
    // setVal(newValue.num);
    setType(valueToType(newValue.value));
    handleClose();
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleClick}
        className={classes.button}
      >
        {selectedOption.icon}
        <span style={{ marginLeft: '8px' }}>{selectedOption.value}</span>
        <ArrowDropDownIcon className={classes.arrowIcon} />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ flex: 1, padding: '0.5rem' }}>
            <optgroup label="General"></optgroup>
            {options.filter((e) => e.sl <= 10).map((option, index) => (
              <MenuItem onClick={() => handleChange(option)} key={index} value={index}>
                <ListItemIcon>
                  {option.icon}
                </ListItemIcon>
                <span style={{ marginTop: 2, fontSize: "14px" }}>{option.value}</span>
              </MenuItem>
            ))}

          </div>
          <Divider orientation="vertical" flexItem style={{ color: "#000", borderRightWidth: 5, borderColor: "#000" }} />
          <div style={{ flex: 1, padding: '0.5rem' }}>
            <optgroup label="Platform"></optgroup>
            {options.filter((e) => e.sl >= 11).map((option, index) => (
              <MenuItem onClick={() => handleChange(option)} key={index} value={index}>
                <ListItemIcon>
                  {option.icon}
                </ListItemIcon>
                <span style={{ marginTop: 2, fontSize: "14px" }}>{option.value}</span>
              </MenuItem>
            ))}
          </div>
        </div>

      </Popover>
    </div>
  );
};

export default QuestionType;
