import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useDebounce } from "react-use";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { Box, Button, CircularProgress, IconButton, InputLabel, Paper, TextField, Typography, Checkbox, Tooltip } from "@material-ui/core";
import Api from "../../helpers/Api";

import DeleteIcon from "@material-ui/icons/Delete";



const useStyles = makeStyles((theme) => ({

    checkListDiv: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // border: '1px solid red',
        width: "100%",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
        // padding: "1rem",
        // marginBottom: "1rem",
        marginTop: '1%',
        position: "relative",
        // overflow: "hidden",
        borderRadius: "15px",
        boxShadow: "rgba(0, 0, 0, 0.22) 4px 4px 4px",
        // height: "250px",
        // overflowY: "scroll",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },

    checkListTag: {
        display: "flex",
        justifyContent: "space-between",
        width: "50%",
        marginTop: "20px",
        // marginLeft: "10px",
        padding: "5px 20px 10px",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
        },
    },
    checkListCont: {
        width: "100%",
        minHeight: "300px",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            marginBottom: "15px",
        },
    },
    textInput: {
        // maxWidth: "24rem",
        width: "60%",
        borderWidth: "1px",
        backgroundColor: "#f1f1f1",
        color: "#424242",
        borderRadius: "11px",
        fontSize: 16,
        zIndex: 0,
    },


    checkListEdit: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        width: "100%",
        // border: "1px solid green",
        justifyContent: "flex-end",
    },
    checkedIcon: {
        position: "absolute",
        fontSize: "130%",
        top: 0,
        right: 0,
    },

    checkListContainer: {
        display: "flex",
        alignitems: "center",
        flexDirection: "row",
        justifyContent: "flex-start",
        width: "100%",
    }

}));

const ApprovalCheckList = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const {
        index,
        taskId,
        // setChecklist,
        AddCheckList,
        DeleteCheckList,
    } = props;

    const [taskItem, setTask] = useState({});
    const [taskText, setTaskText] = useState("");
    const [taskDescription, setTaskDescription] = useState("");
    const [taskClosed, setTaskClosed] = useState();

    const getTaskById = async () => {
        try {
            const res = await Api.post("task/getById", {
                taskId: taskId,
            });
            const data = res?.data;
            if (data) {
                setTask(data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (taskId) {
            getTaskById();
        }
    }, []);

    useEffect(() => {
        setTaskText(taskItem?.text || "");
        setTaskDescription(taskItem?.description || "");
        setTaskClosed(taskItem?.closed);
    }, [taskItem]);


    const handleCheck = (event) => {
        setTaskClosed(event.target.checked)
    }

    const UpdateTask = async () => {
        const obj = {
            _id: taskItem?._id,
            text: taskText,
            description: taskDescription,
            closed: taskClosed,
        };
        const res = await Api.post("task/update", obj);

        const data = res?.data;
        if (res?.status === "success") {
            dispatch({
                type: "AddApiAlert",
                payload: {
                    success: true,
                    message: "Update CheckList successfully",
                },
            });
        } else {
            dispatch({
                type: "AddApiAlert",
                payload: {
                    success: false,
                    message: "CheckList Update failed",
                },
            });
        }
    };

    // useDebounce(
    //     () => {
    //         UpdateTask({ ...taskItem, text: taskText });
    //     },
    //     300,
    //     [taskText]
    // );


    useEffect(() => {
        if (taskItem) {
            UpdateTask();
        }
    }, [taskText, taskDescription, taskClosed, taskItem]);


    return (

        <Paper className={classes.checkListDiv} variant="outlined" key={index}>
            <div className={classes.checkListContainer}>
                <Checkbox
                    // className={classes.checkedIcon}
                    checked={taskClosed}
                    color="primary"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                    onClick={handleCheck}
                />

                {/* <InputBase className={classes.textInput} /> */}
                <TextField
                    style={{ width: "80%" }}
                    label="text"
                    placeholder="text"
                    type="text"
                    variant="outlined"
                    margin="normal"
                    value={taskText}
                    onChange={(e) => setTaskText(e.target.value)}
                />

                {/* <TextField
                        style={{ marginTop: "0%" }}
                        id="outlined-multiline-static"
                        label="Description"
                        placeholder="Description"
                        multiline
                        variant="outlined"
                        fullWidth
                        rows={2}
                        value={taskDescription}
                        onChange={(e) => setTaskDescription(e.target.value)}
                    /> */}

            </div>

            <div className={classes.checkListEdit}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "15%",
                    }}
                >
                    <Tooltip title="Add new">
                        <IconButton
                            aria-label="Add"
                            onClick={() => {
                                // onAdd(index);
                                AddCheckList();
                            }}
                        >
                            <AddCircleOutlineIcon />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Delete">
                        <IconButton
                            aria-label="Delete"
                            onClick={() => {
                                DeleteCheckList(taskItem._id);
                            }}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>

        </Paper>

    )
}

export default ApprovalCheckList