import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Avatar, Paper, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import {
  FaUsers,
  FaFileInvoiceDollar,
  FaFileInvoice,
  FaTasks,
  FaReceipt,
} from "react-icons/fa";
import { IoMdAnalytics } from "react-icons/io";
import { MdRequestQuote } from "react-icons/md";
import { Add } from "@mui/icons-material";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import LessText from "../styled/CommonComponents/LessText";
import CreateIcon from "@material-ui/icons/Create";
import { FaBuilding, FaExchangeAlt, FaRegFileAlt, FaCertificate, FaFileSignature } from 'react-icons/fa';
import { VscNotebookTemplate } from "react-icons/vsc";
import { AiFillThunderbolt } from "react-icons/ai";
import { Tooltip } from "@material-ui/core";
import { approvalTypesDefaultFieldsMap } from "../../helpers/approval";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "170px",
    width: "500px",
    minWidth: "500px",
    marginRight: "30px",
    padding: "10px",
    display: "flex",
    justifyContent: "space-between",
  },
  avatarSty: {
    width: "60px",
    height: "60px",
    borderRadius: "10px",
  },
  iconSty: {
    width: "80%",
    height: "80%",
    color: theme.palette.primary.main,
  },
  detailsCont: {
    width: "calc(100% - 165px)",
    height: "150px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "& h2": {
      width: "100%",
      fontSize: "18px",
      fontWeight: "600",
    },
    "& h3": {
      width: "100%",
      fontSize: "15px",
      fontWeight: "500",
    },
    "& p": {
      width: "100%",
      fontSize: "13px",
      fontWeight: "400",
    },
  },
  btnCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },

  approvalContainer: {
    height: "150px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  approvalUp: {
    width: "100%",
    display: "flex",
    justifyItems: "start",
    gap: "1rem",
  },
  approvalDown: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "1rem",
  },
  approvalSection: {
    height: "85%",
    width: "93%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "relative",
  },

  buttonsContainer: {
    top: "0rem",
    right: "0.2rem",
    position: "absolute",
    display: "flex",
    gap: "1rem",
    alignItems: "center",
  },
  h2: {
    fontSize: "1.1rem",
    fontWeight: "semibold",
  },
  requestBtn: {
    width: "9rem",
    height: "2rem",
    backgroundColor: "rgb(59 130 246)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    borderRadius: "5px",
  },
}));

const ApprovalTemplateCard = ({ approvalTemplate, onApprovalEditBtnClick, buildingCodeLibrary, updateDefault }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { user } = useSelector((state) => state.auth);

  const getIconAgainstType = (type) => {
    switch (type) {
      case "HRM":
        return <FaUsers className={classes.iconSty} color="blue" />;
      case "Material Request":
        return <MdRequestQuote className={classes.iconSty} color="green" />;
      case "Bill Of Quantities":
        return <FaFileInvoiceDollar className={classes.iconSty} color="purple" />;
      case "Purchase Order":
        return <FaFileInvoice className={classes.iconSty} color="orange" />;
      case "Work Order":
        return <FaTasks className={classes.iconSty} color="red" />;
      case "GRN":
        return <FaReceipt className={classes.iconSty} color="teal" />;
      case "Estimation":
        return <IoMdAnalytics className={classes.iconSty} color="orange" />;
      case "MultiFamilySalesBooking":
        return <FaBuilding className={classes.iconSty} color="navy" />;
      case "Transaction":
        return <FaExchangeAlt className={classes.iconSty} color="gold" />;
      case "Transaction Template":
        return <VscNotebookTemplate className={classes.iconSty} />;
      case "Financial Certificate":
        return <FaCertificate className={classes.iconSty} color="green" />;
      case "Debit/Credit Note":
        return <FaFileSignature className={classes.iconSty} color="brown" />;
      default:
        return <IoMdCheckmarkCircleOutline className={classes.iconSty} />;
    }
  };

  const getLableAgainstType = (type) => {
    switch (type) {
      case "HRM":
        return "Human resource management";
      case "Material Request":
        return "Material Request";
      case "Bill Of Quantities":
        return "Bill Of Quantities";
      case "Purchase Order":
        return "Purchase Order";
      case "Work Order":
        return "Work Order";
      case "GRN":
        return "Goods Received Note";
      case "Estimation":
        return "Estimation";
      case "MultiFamilySalesBooking":
        return "Multi-Family Sales Booking";
      case "Transaction":
        return "Transaction";
      case "Transaction Template":
        return "Transaction Template";
      case "Financial Certificate":
        return "Financial Certificate";
      case "Debit/Credit Note":
        return "Debit/Credit Note";
      default:
        return type;
    }
  };

  return (
    <Paper className={classes.approvalContainer}>
      <div className={classes.approvalSection}>
        <div className={classes.buttonsContainer}>
          {buildingCodeLibrary[approvalTypesDefaultFieldsMap[approvalTemplate?.type]] == approvalTemplate?._id ? null : (
            <Tooltip title={"Mark As Default"} placement="top">
              <span
                onClick={() => {
                  updateDefault(approvalTemplate?.type, approvalTemplate?._id)
                }}
              >
                <AiFillThunderbolt style={{ fontSize: "22px", cursor: "pointer" }} />
              </span>
            </Tooltip>
          )}
          <Tooltip title={"Edit"} placement="top">
            <span
              onClick={() => {
                onApprovalEditBtnClick(approvalTemplate?._id);
              }}
            >
              <CreateIcon style={{ fontSize: "22px", cursor: "pointer" }} />
            </span>
          </Tooltip>
        </div>
        <div className={classes.approvalUp}>
          <Avatar variant="square" className={classes.avatarSty}>
            {getIconAgainstType(approvalTemplate?.type)}
          </Avatar>
          <div>
            <h2 className={classes.h2}>
              {getLableAgainstType(approvalTemplate?.type)}
            </h2>
            <h3>
              <LessText limit={35} string={approvalTemplate?.title} />
            </h3>
          </div>
        </div>
        <div className={classes.approvalDown}>
          <div>
            <p>Request: 0</p>
            <p>To Review: 0</p>
          </div>
          <div>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<Add />}
            >
              New Request
            </Button>
          </div>
        </div>
      </div>
    </Paper>
  );
};

export default ApprovalTemplateCard;
