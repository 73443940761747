import React, { useEffect, useState } from "react";
import StandardContainer from "../styled/generic/StandardContainer";
import Button from "../styled/generic/Button";
import StandardAppContainer from "../styled/generic/StandardAppContainer";
import StandardAppContainerRounded from "../styled/generic/StandardAppContainerRounded";
import {
  Avatar,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import HorizBox from "../styled/generic/HorizBox";
import FormBox from "../styled/generic/FormBox";
import TextField from "../styled/generic/TextField";
import DocumentUploader from "../styled/generic/DocumentUploader";
import DocViewer from "../styled/generic/DocViewer";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Api from "../../helpers/Api";
import Select from "../styled/generic/Select";
import dayjs from "dayjs";
import DatePicker from "../styled/generic/DatePicker";
import FormHeaderWithActions from "../styled/generic/FormHeaderWithActions";
import BoxSpaceBetween from "../styled/generic/BoxSpaceBetween";
import ExpandMore from "../styled/generic/ExpandMore";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ApprovalRequest = () => {
  const dispatch = useDispatch();
  const { requestId } = useParams();
  const [leaves, setLeaves] = useState([]);
  const [leavesLoading, setLeavesLoading] = useState(false);
  const [requestData, setRequestData] = useState({});
  const [org, setOrg] = useState();
  const [selectedLeave, setSelectedLeave] = useState("");
  const [fromDate, setFromDate] = useState(dayjs());
  const [toDate, setToDate] = useState(dayjs().add(1, "day"));

  const getRequestData = async () => {
    try {
      const { data } = await Api.post("/approval/request/get-by-id", {
        approvalRequestId: requestId,
      });
      if (data) {
        setRequestData(data);
        setOrg(data?.approvalTemplate?.organization);
      }
    } catch (error) {
      console.log("Error: ", error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to fetch approval requests",
        },
      });
    }
  };

  const getLeaves = async () => {
    try {
      setLeavesLoading(true);
      const { data } = await Api.post("/leave/getAll", {
        organization: org?._id,
      });

      if (data) {
        setLeaves(data);
        setSelectedLeave(data[0]?._id || "");
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to get leaves",
        },
      });
    } finally {
      setLeavesLoading(false);
    }
  };

  useEffect(() => {
    getRequestData();
  }, []);

  useEffect(() => {
    getLeaves();
  }, [org]);

  const [expanded, setExpanded] = useState({});
  useEffect(() => {
    setExpanded({
      1: true,
      2: true,
    });
  }, []);

  return (
    <StandardContainer
      showAppBar={true}
      appBarTitle="Approval Request"
      appBarActions={
        <Button variant="contained" color="primary">
          Save
        </Button>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <StandardAppContainerRounded>
            <HorizBox spacing={1}>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Approval Type:
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Travel Request
              </Typography>
            </HorizBox>
            <FormBox label="Description" sx={{ mt: 2 }}>
              <TextField multiline rows={4} fullWidth placeholder="Enter" />
            </FormBox>
            <FormBox label="Add File">
              <DocumentUploader
                documents={[]}
                onDelete={() => {}}
                onUpload={() => {}}
              />
            </FormBox>
            <FormBox label="Leave">
              <Select
                fullWidth
                value={selectedLeave}
                onChange={(e) => {
                  setSelectedLeave(e.target.value);
                }}
                displayEmpty
              >
                <MenuItem value="" disabled>
                  Select
                </MenuItem>
                {leaves.map((leave) => (
                  <MenuItem value={leave._id}>{leave.name}</MenuItem>
                ))}
              </Select>
            </FormBox>
            <FormBox label="From Date">
              <DatePicker
                value={fromDate}
                onChange={(date) => setFromDate(date)}
              />
            </FormBox>
            <FormBox label="To Date">
              <DatePicker value={toDate} onChange={(date) => setToDate(date)} />
            </FormBox>
          </StandardAppContainerRounded>
        </Grid>
        <Grid item xs={6}>
          <StandardAppContainerRounded>
            <FormHeaderWithActions
              label="Approvers"
              actions={
                <FormControlLabel control={<Checkbox />} label="Notify all" />
              }
              showBorderBottom={true}
            />
            {[1, 2].map((item, index) => (
              <Box
                sx={{
                  p: 1,
                  borderRadius: "12px",
                  mt: 2
                }}
              >
                <BoxSpaceBetween>
                  <HorizBox spacing={1}>
                    <ExpandMore
                      expand={expanded[item]}
                      onClick={() => {
                        let newExpanded = { ...expanded };
                        newExpanded[item] = !expanded[item];
                        setExpanded(newExpanded);
                      }}
                      aria-expanded={expanded[item]}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </ExpandMore>
                    <Typography variant="h6">Stage {index + 1}</Typography>
                  </HorizBox>
                  <Box>
                    <Button variant="outlined" color="primary">
                      Invite
                    </Button>
                  </Box>
                </BoxSpaceBetween>
                <Box
                  sx={{
                    display: expanded[item] ? "block" : "none",
                  }}
                >
                  {[1, 2, 3, 4].map((approver) => (
                    <Box sx={{ p: 1, ml: 4 }}>
                      <HorizBox>
                        <Avatar />
                        <Typography variant="h6">User Name</Typography>
                      </HorizBox>
                    </Box>
                  ))}
                </Box>
              </Box>
            ))}
          </StandardAppContainerRounded>
        </Grid>
      </Grid>
    </StandardContainer>
  );
};

export default ApprovalRequest;
