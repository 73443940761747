import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Api from "../../helpers/Api";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    useHistory,
} from "react-router-dom";

import { CircularProgress, LinearProgress } from "@material-ui/core";
// import ProfileAppbar from "../profile/profile.appbar";
import EditPermitApproval from "./editPermitApproval"

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
    },
    center: {
        display: "flex",
        justifyContent: "center",
        minHeight: "80vh",
        alignItems: "center",
    },
}));

function PermitApprovalEditManager(props) {
    const classes = useStyles();
    const history = useHistory();
    const { permitId } = useParams();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);



    const getData = async () => {
        try {
            const res = await Api.post("approvalPermit/getPermitById", {
                permitId: permitId,
            });
            const data = res?.data;
            if (data) {
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getData();
    }, [permitId])

    return (
        <div className={classes.root}>
            {loading ? (
                <div className={classes.center}>
                    <CircularProgress />
                </div>
            ) : (
                <>
                    <EditPermitApproval
                        permitId={permitId}
                        setLoading={setLoading}
                    />

                </>
            )}
        </div>
    );
}

export default PermitApprovalEditManager;
