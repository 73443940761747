import React from "react";
import StandardContainer from "../styled/generic/StandardContainer";
import HorizBox from "../styled/generic/HorizBox";
import Button from "../styled/generic/Button";
import { Avatar, Grid, Typography } from "@mui/material";
import StandardAppContainerRounded from "../styled/generic/StandardAppContainerRounded";
import StatusHandler from "../styled/generic/StatusHandler";
import DateText from "../styled/generic/DateText";
import SpaceBetween from "../styled/generic/SpaceBetween";
import FormBox from "../styled/generic/FormBox";
import BoxSpaceBetween from "../styled/generic/BoxSpaceBetween";

const ApprovalPage = () => {
  return (
    <StandardContainer
      showAppBar={true}
      appBarTitle="Order Approval"
      appBarActions={
        <HorizBox>
          <Button variant="outlined" color="primary">
            Activity Log
          </Button>
          <Button variant="contained" color="primary">
            Share
          </Button>
        </HorizBox>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <StandardAppContainerRounded>
            <BoxSpaceBetween>
              <HorizBox>
                <Avatar />
                <Typography variant="h6">User Name</Typography>
              </HorizBox>

              <StatusHandler
                label="Pending"
                warning={["Pending"]}
                success={["Approved"]}
              />
            </BoxSpaceBetween>

            <DateText
              prefix="Created at "
              date={new Date()}
              variant="subtitle1"
              sx={{ fontWeight: "bold", mt: 1 }}
            />

            <FormBox label="Request Details" sx={{ mt: 2 }}>
              <Typography variant="subtitle1" gutterBottom>
                Leave Type: Sick Leave
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                From Date: 22-06-23
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                To Date: 22-06-23
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Reason: Enter the reason for sick leave
              </Typography>
            </FormBox>
          </StandardAppContainerRounded>
        </Grid>
        <Grid item xs={6}>
          <StandardAppContainerRounded>B</StandardAppContainerRounded>
        </Grid>
      </Grid>
    </StandardContainer>
  );
};

export default ApprovalPage;
