import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
    Avatar,
    Box,
    Checkbox,
    FormControl,
    InputBase,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@material-ui/core'

import Api from "../../helpers/Api";
import Response from "../responses/response";
import { useDebounce } from "react-use";
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    root: {

    },
    text: {
        fontSize: 20,
        fontWeight: "bold",
        margin: "0rem 1rem",
        fontColor: "#111111",
    },
    title: {
        width: '39rem',
        margin: "0rem 1rem",
        backgroundColor: '#e9e9e9'
    },
    textInput: {
        borderWidth: "1px",
        backgroundColor: "#f1f1f1",
        color: "black",
        borderRadius: "11px",
        fontSize: 16,
        zIndex: 0,
        padding: '0px 10px 3px',
        width: '100%'
    },
    formControl: {
        marginRight: '1%',
        marginTop: '1%',
        width: '13rem'
    },
    checkListContainer: {
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "flex-start",
        margin: '5px 5px 5px 5px',
        width: "100%",
    },
    avatar: {
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
}))

const approvalStatuses = [
    "Pending",
    "Clarification",
    "Deferred",
    "Approved",
    "Rejected"
]

const AnswerApproval = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const { approvalId, loader, setLoader } = props;
    const { user } = useSelector((state) => state.auth);

    const [approval, setApproval] = useState(null);
    const [questions, setQuestions] = useState([]);
    const [approvalStatus, setApprovalStatus] = useState("");
    const [checklist, setChecklist] = useState([]);



    const getApprovalById = async () => {
        try {
            const res = await Api.post("approvalPermit/getApprovalById", {
                approvalId: approvalId,
            });
            const data = res?.data;
            if (data) {
                setApproval(data);
                setLoader(true);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (approvalId) {
            getApprovalById();
        }
    }, [])

    // useDebounce(
    //     () => {
    //         if (questionId) {
    //             updateApi({
    //                 _id: approvalId,
    //                 type,
    //                 questionText,
    //                 questionImage,
    //                 links,
    //             });
    //         }
    //     },
    //     100,
    //     [type, questionText, questionImage, links]
    // );

    // const updateApi = async (question) => {
    //     const res = await Api.post("question/update", question);
    // };

    const UpdateTask = async (Id, event) => {
        const obj = {
            _id: Id,
            closed: event.target.checked,
        };
        const res = await Api.post("task/update", obj);

        const data = res?.data;
        if (res?.status === "success") {
            dispatch({
                type: "AddApiAlert",
                payload: {
                    success: true,
                    message: "Update CheckList successfully",
                },
            });
            getApprovalById();
        } else {
            dispatch({
                type: "AddApiAlert",
                payload: {
                    success: false,
                    message: "CheckList Update failed",
                },
            });
        }
    };

    const updateStatus = async () => {

        const Id = approvalId
        const obj = {
            status: approvalStatus,
            // shared: sharedId
        }
        const hisData = {
            history: [{
                status: approvalStatus,
                timestamp: new Date(),
                user: user._id,
                profile: user.profile
            }]
        }
        const res = await Api.post("approvalPermit/updateApproval", { approvalId: Id, updateData: obj, historyData: hisData });

        const data = res?.data;
        if (data?._id) {
            dispatch({
                type: "AddApiAlert",
                payload: {
                    success: true,
                    message: "Approval Updated successfully",
                },
            });
            getApprovalById();
            // history.goBack();
        } else {
            dispatch({
                type: "AddApiAlert",
                payload: {
                    success: false,
                    message: "Approval Updated failed",
                },
            });
        }
    };

    useEffect(() => {
        setQuestions(approval?.questions || [])
        setApprovalStatus(approval?.status || "");
        setChecklist(approval?.checklist || []);
    }, [approval])

    useEffect(() => {
        if (approvalStatus !== '' && approvalStatus !== approval?.status) {
            updateStatus();
        }
    }, [approvalStatus])
    return (
        <>
            {!(approval?.status === 'Approved' || approval?.status === 'Rejected') && <Box
                component={Paper}
                elevation={2}
                style={{
                    backgroundColor: "#ffffff",
                    padding: "0.5rem",
                    marginTop: "1rem",
                    width: '70%',
                    marginLeft: '5%'
                }}
            >

                <Box>
                    <div style={{
                        display: 'flex',
                        justifyContent: "space-between"
                    }}>
                        <Box>
                            <Typography variant="h5" className={classes.text}>
                                Checked
                            </Typography>

                            {checklist && checklist.length > 0 ? (
                                checklist.map((item, index) => (
                                    <div className={classes.checkListContainer}>
                                        <Checkbox
                                            // className={classes.checkedIcon}
                                            checked={item?.closed}
                                            color="primary"
                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                            onClick={(e) => UpdateTask(item._id, e)}
                                        />
                                        <Typography>{item?.text}</Typography>
                                    </div>
                                ))
                            ) : (
                                <p>No Checklist Added</p>
                            )}
                        </Box>

                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label">Approval Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={approvalStatus}
                                onChange={(e) => setApprovalStatus(e.target.value)}
                                label="Permit Status"
                            >
                                {approvalStatuses.map((val, index) => (
                                    <MenuItem key={index} value={val}>{val}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>

                    <Box width="100%" style={{ marginTop: "1rem" }}>
                        <Typography variant="h5" className={classes.text}>
                            Questions
                        </Typography>

                        <Box width="100%" style={{ padding: "1rem" }}>
                            <Response
                                questions={questions}
                            // issue={issue}
                            />
                        </Box>
                    </Box>

                </Box>
            </Box >}

            {(approval?.status === 'Approved' || approval?.status === 'Rejected') &&
                <Box elevation={2}
                    style={{
                        // backgroundColor: "#ffffff",
                        padding: "0.5rem",
                        marginTop: "1rem",
                        width: '70%',
                        marginLeft: '5%'
                    }}>
                    <div style={{
                        display: 'flex',
                        justifyContent: "space-between"
                    }}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Time Stamp</TableCell>
                                        <TableCell align="center">Status</TableCell>
                                        <TableCell align="center">Signed</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {approval?.history.map((data, index) => (
                                        <TableRow key={data._id}>
                                            <TableCell component="th" scope="row">
                                                {moment(data.timestamp).format('DD-MM-YYYY h:mm A')}
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                style={{
                                                    color: data.status === 'Approved' ? 'green' : (data.status === 'Rejected' ? 'red' : 'black')
                                                }}
                                            >
                                                {data.status}
                                            </TableCell>
                                            <TableCell align="center">
                                                <div style={{
                                                    display: "flex",
                                                    // alignItems: "center",
                                                    justifyContent: "center",
                                                }}>
                                                    <Avatar
                                                        src={data.user?.displayPicture.url}
                                                        className={classes.avatar}
                                                    />{" "}
                                                    {data.user?.displayName}
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </div>
                </Box >}
        </>
    )
}

export default AnswerApproval